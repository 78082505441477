<template>
    <div class="box flex">
        <!-- 通话 -->
        <div v-if="isTelephone && !isOpen" :class="['dh', $route.meta.navId == 1 ? 'dh_left' : '']">
            <img class="horn1 horn" src="../../assets/video/horn1.png" alt="">
            <img class="horn2 horn" src="../../assets/video/horn2.png" alt="">
            <img class="horn3 horn" src="../../assets/video/horn3.png" alt="">
            <img class="horn4 horn" src="../../assets/video/horn4.png" alt="">
            <div class="dh_box">
                <div class="dh_title">
                    <div v-if="$store.state.voiceCallInfo.isAnswerVoice" class="dh_title_text">{{ timeData }}</div>
                    <div v-else class="dh_title_text">{{$store.state.voiceCallInfo.callType==1?'等待对方接听语音':'邀请你语音通话' }}...</div>
                    <img class="mouse" @click="narrow(0)" src="../../assets/conversation/sx.png" alt="">
                </div>
                <div class="dh_logo">
                    <img src="../../assets/conversation/thicon.png" alt="">
                    <div style="margin-top: 15px;">{{ $store.state.voiceCallInfo.callName }}</div>
                </div>
                <div class="dh_bottom flex mouse" v-if="callType == 0">
                    <img @click="hangup()" src="../../assets/conversation/gd.png" alt="">
                </div>
                <div class="dh_bottom flex mouse" v-else >
                    <img  @click="hangup()" src="../../assets/conversation/gd.png" alt="">
                    <img style="margin-left: 20%;" v-if="!$store.state.voiceCallInfo.isAnswerVoice" @click="answerVoiceCall(0)" src="../../assets/conversation/jt.png" alt="">
                </div>
            </div>
        </div>
        <div :class="['xt flex mouse', $route.meta.navId == 1 ? 'dh_left' : '']" v-show="isOpen" @click="narrow(2)">
            <div style="width: 100%;text-align:center;">
                <img class="xt_dh" v-if="type == 0" src="../../assets/conversation/thsx.png" alt="">
                <img class="xt_dh" v-else src="../../assets/conversation/spsx.png" alt="">
                <div>{{ timeData }}</div>
            </div>
        </div>
    </div>
</template>
<script></script>
<script>
// import axios from "axios";
// import qs from "qs";
// import beasconfig from "@/api/beasconfig.js"
import EventBus from "@/eventBus/eventBus.js";
import videoCall from "@/method/wav/video_call.mp3";
export default {
    props: {
        // isTelephone: {      //通话显示
        //     type: Boolean,
        //     default: false
        // },
        // isVideo: {      //视频显示
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            isTelephone: false,       //通话显示
            isVideo: false,           //视频显示
            type: 0,              //显示类型
            isOpen: false,            //电话缩小
            isLoading:true,    //加载中
            timer: '',           //计时
            timeData: '00:00:00',
            videoWidth: 600,
            videoHeight: 600,
            timerOne: null,
            userInfo: null,
            thisVideo: null,
            ppllaayyeerr:null,
            ownPlayer:null,
            userPlayer:null,
            clientWidth:100,
            clientHeight:800,        //页面高度
            videpType:0,
            isMute:true,
            callType:0,      //呼叫类型
            isAnswerVoice:false
        };
    },
    created() {
        let that = this;
        EventBus.$on("closeConversation", function (type) {     //关闭通话
            that.jsTime();
            that.jsTimeOne();
            that.returnData();
            if(type == 1){
                EventBus.$emit('sendRespond',0,0);
            }
        });
        EventBus.$on("answerVoice", function () {     //开启通话
            that.answerVoiceCall(1);
        });
    },
    beforeDestroy: function () {
        if(this.$store.state.voiceCallInfo.callType > 0){
                EventBus.$emit('sendRespond',0,0);
                this.returnData();
        }
        EventBus.$off("closeConversation");
        EventBus.$off("answerVoice");
    },
    mounted() {
        this.clientHeight = Math.round(document.documentElement.clientHeight - 78 - 90);
        this.clientWidth = Math.round(this.clientHeight * 0.75);
    },
    methods: {
        playAudio(type){        //播放音频
            if(this.lsOnCall != null){
                this.lsOnCall.pause();
                this.lsOnCall = null;
            }
           if(type == 1){
            this.lsOnCall = new Audio(videoCall);
            this.lsOnCall.play();
            this.lsOnCallTime =  setInterval(()=>{
                this.lsOnCall = new Audio(videoCall);
                this.lsOnCall.play();
            },5000)
           }else{
             clearInterval(this.lsOnCallTime);
             this.lsOnCallTime = null;
           }
        },
        openPhone(type) {        //打开通话弹出框
            this.callType = type;
            this.isTelephone = true;
            // if (type == 0) {
            //     this.isTelephone = true;
            // } else {
            //     this.isVideo = true;
            this.playAudio(1);
            this.startTimeOne();
            //     // this.thisVideo = document.getElementById("video1");
            // }
        },
        switchVideo(type){      //切换放大页面
            if(this.videpType == type){

                return
            }
            this.videpType = type;
        },
        narrow(type) {       //缩小 放大
            if (type == 2) {
                this.isOpen = false;
                if (this.type == 0) {
                    this.isTelephone = true;
                } else {
                    this.isVideo = true;
                }
            } else {
                this.type = type;
                this.isOpen = true;
                this.isTelephone = false;
                this.isVideo = false;
            }
        },
        startTime() {        //开始计时
            let that = this;
            let n_sec = 0;
            let n_min = 0;
            let n_hour = 0;
            that.jsTime();
            function timer() {
                return setInterval(() => {
                    var str_sec = n_sec;
                    var str_min = n_min;
                    var str_hour = n_hour;
                    if (n_sec < 10) {
                        str_sec = "0" + n_sec;
                    }
                    if (n_min < 10) {
                        str_min = "0" + n_min;
                    }
                    if (n_hour < 10) {
                        str_hour = "0" + n_hour;
                    }
                    that.timeData = str_hour + ":" + str_min + ":" + str_sec;
                    n_sec++;
                    if (n_sec > 59) {
                        n_sec = 0;
                        n_min++;
                    }
                    if (n_min > 59) {
                        n_sec = 0;
                        n_hour++;
                    }

                    if(str_min == 10){      //10分钟自动挂断
                        that.hangup();
                    }
                }, 1000)
            }
            that.timer = timer();
        },
        jsTime() {      //结束计时
            if(this.timer != null){
                clearInterval(this.timer)
                this.timer = "";
                this.timeData = '00:00:00';
                // this.isTelephone = false;
            }
        },
        startTimeOne() {        //30s没人接直接挂断
            let that = this;
            that.jsTimeOne();
            if (that.timerOne == null) {
                that.startTime();
                that.timerOne = setTimeout(() => {
                    if(that.$store.state.voiceCallInfo.callType == 1){
                        that.$MessageWarning('对方无应答！');
                        that.hangup();
                    }
                }, 62000);
            }
        },
        jsTimeOne() {      //结束计时
            if(this.timerOne != null){
                clearTimeout(this.timerOne)
                this.timerOne = null;
                // this.isTelephone = false;
            }
        },
        answerVoiceCall(type){  //接听电话
            if(type == 0){
                EventBus.$emit('sendRespond',1,1);
            }
            this.playAudio(2);
            this.$store.state.voiceCallInfo.isAnswerVoice = true;
            this.jsTime();
            this.jsTimeOne();
            this.startTime();
        },
        hangup(){
            if(this.$store.state.voiceCallInfo.isAnswerVoice){
                EventBus.$emit('sendRespond',0,0,1);
            }else{
                EventBus.$emit('sendRespond',0,0);
            }
            this.jsTime();
            this.jsTimeOne();
            this.returnData();
        },
        returnData(){       //清空状态
            this.playAudio(2);
            this.isTelephone = false;
            this.isOpen = false;
            this.$store.state.voiceCallInfo.isAnswerVoice = false;
            this.$store.state.voiceCallInfo.callType = 0;
            sessionStorage.removeItem('fullduplex');
        }
    },
    watch: {
       
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.box {
    // background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    // width: 100%;
    // height: 750px;

    .dh_left {
        left: 440px !important;
    }

    .dh {
        width: 240px;
        height: 400px;
        background-color: #033542;
        border-radius: 5px;
        overflow: hidden;
        position: fixed;
        bottom: 20px;
        left: 405px;
        z-index: 1000;

        &_box {
            width: 100%;
            height: 100%;
            // background: url("../../assets/group/leftBg1.png");
            // background-size: 100% 100%;
            margin: 0px auto;
            font-size: 12px;
            position: relative;
        }

        &_title {
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0px;
            position: absolute;
            top: 0px;
            // left: 0px;
            right: 0px;

            &_text {
                flex: 1;
                text-align: center;
            }

            img {
                width: 17px;
                height: 17px;
                position: absolute;
                right: 10px;
                z-index: 10;
            }
        }

        &_logo {
            width: 100%;
            text-align: center;
            font-size: 16px;
            position: absolute;
            top: 10vh;

            img {
                width: 70px;
                height: 70px;
            }
        }

        &_bottom {
            width: 100%;
            position: absolute;
            bottom: 25px;
            left: 0px;
            justify-content: center;

            img {
                width: 44px;
                height: 44px;
            }
        }
    }

    .xt {
        width: 65px;
        height: 65px;
        position: fixed;
        bottom: 90px;
        left: 405px;
        background-color: #033542;
        font-size: 12px;
        border-radius: 10px;
        z-index: 1000;

        &_dh {
            width: 28px;
            height: 24px;
        }
    }
}


.horn {
    width: 13px;
    height: 12px;
}

.horn1 {
    position: absolute;
    left: -2px;
    top: -1px;
    z-index: 5;
}

.horn2 {
    position: absolute;
    right: -2px;
    top: -1px;
    z-index: 5;
}

.horn3 {
    position: absolute;
    left: -2px;
    bottom: -1px;
    z-index: 5;
}

.horn4 {
    position: absolute;
    right: -2px;
    bottom: -1px;
    z-index: 5;
}
.flex {
    display: flex;
    align-items: center;
}
</style>