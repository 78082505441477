<template>
    <!-- <div class="box flex" v-if="dialogTableVisible"> -->
    <el-dialog :title="dialogTitle ? dialogTitle : '编辑'" :append-to-body="true" :before-close="gbDialog" :width="width"
        :visible.sync="dialogTableVisible" center>
        <!-- 编辑 -->
        <div class="dialog flex_between" style="height: 540px;">
            <div class="dialog_left mouse">
                <div class="dialog_left_groupBg" :class="groupId == item.id ? 'dialog_left_bgOne' : ''"
                    v-for="item in groupArray" :key="item.id" @click="bindSwitchGb(item.id, 2)">
                    {{ item.name }}
                </div>
            </div>
            <div class="dialog_right">
                <div class="dialog_group" v-if="groupId == 1">
                    <div class="dialog_group_top flex">
                        <div>群组名称 ：</div>
                        <div class="dialog_group_input">
                            <el-input v-model.trim="groupName" @focus="focus" @blur="blur" @input="bindInput"
                                maxlength="15" placeholder="请输入群组名称">
                            </el-input>
                        </div>
                        <div class="dialog_group_red">*由2-15位数字,中文,英文组成</div>
                    </div>
                    <!-- 开始 -->
                    <div class="dialog_group_user flex_between">
                        <div class="dialog_group_user_text">
                            <div>
                                选择用户 :
                            </div>
                            <div class="dialog_group_red" style="margin-top: 10px;">
                                *编辑即生效
                            </div>
                        </div>
                        <div class="dialog_group_user_xz">
                            <div class="flex">
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">固定在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                                    placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(1)">
                                                <img src="@/assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <el-tree @check="checkOne" :data="InGroupUser.userArray" show-checkbox
                                                node-key="id" default-expand-all :expand-on-click-node="false">
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:40px;margin-top: 65px;" class="dialog_group_user_but">
                                    <el-tooltip content="全部移除" placement="top">
                                        <img class="mouse" src="@/assets/group/wholemove.png" @click="changeGroup(1)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="移除选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/moveuser.png" @click="changeGroup(2)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="添加选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/adduser.png" @click="changeGroup(3)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="全部添加" placement="top">
                                        <img class="mouse" src="@/assets/group/wholeadd.png" @click="changeGroup(4)"
                                            alt="">
                                    </el-tooltip>
                                </div>
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">不在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="noGroupUser.searchName" @focus="focus" @blur="blur"
                                                    placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img @click="bindEmpty(2)" class="mouse" src="@/assets/group/gb.png"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(2)">
                                                <img src="@/assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <el-tree ref="treeTow" :data="noGroupUser.userArray" @check="checkTow"
                                                show-checkbox node-key="id" default-expand-all
                                                :expand-on-click-node="false">
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">临时在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="InGroupUserTow.searchName" @focus="focus"
                                                    @blur="blur" placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img @click="bindEmpty(3)" class="mouse" src="@/assets/group/gb.png"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(3)">
                                                <img src="@/assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <el-tree @check="checkThree" :data="InGroupUserTow.userArray" show-checkbox
                                                node-key="id" default-expand-all :expand-on-click-node="false">
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:40px;margin-top: 65px;" class="dialog_group_user_but">
                                    <el-tooltip content="全部移除" placement="top">
                                        <img class="mouse" src="@/assets/group/wholemove.png" @click="changeGroup(5)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="移除选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/moveuser.png" @click="changeGroup(6)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="添加选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/adduser.png" @click="changeGroup(7)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="全部添加" placement="top">
                                        <img class="mouse" src="@/assets/group/wholeadd.png" @click="changeGroup(8)"
                                            alt="">
                                    </el-tooltip>
                                </div>
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">不在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="noGroupUserTow.searchName" @focus="focus"
                                                    @blur="blur" placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img @click="bindEmpty(4)" class="mouse" src="@/assets/group/gb.png"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(4)">
                                                <img src="@/assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <el-tree :data="noGroupUserTow.userArray" @check="checkFour" show-checkbox
                                                node-key="id" default-expand-all :expand-on-click-node="false">
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 结束 -->
                </div>
            </div>
        </div>
        <!-- 编辑结束 -->
        <div class="bottom">
            <el-button class="bc" @click="getSendOut($event)" @keyup.prevent @keydown.enter.prevent>{{ butName }}
            </el-button>
            <el-button class="gb" @click="gbDialog()">{{ cancelBut }}</el-button>
        </div>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import { queryRegionMember, queryRegionUnMember } from "@/administration/dispatchLntercom.js";
import beasconfig from '@/api/beasconfig.js';
import regfunc from "@/method/form/regfunc.js";
const regionUrlapi = beasconfig.regionUrlapi;
import qs from "qs";
import axios from 'axios';
// import moment from 'moment';
export default {
    props: {
        dialogTitle: {
            type: String,
            default: ''
        },
        timeShow: {      //选择时间
            type: Boolean,
            default: false
        },
        width: {     //弹出框的宽
            type: String,
            default: '925px'
        },
        butName: {       //按钮名称
            type: String,
            default: '保存'
        },
        cancelBut: {       //取消按钮
            type: String,
            default: '取消'
        },
        regionId: {
            type: Number,
            default: 0
        },
        Gid: {       //群组id
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            userInfo: {

            },
            dialogTableVisible: true,
            groupArray: [
                {
                    id: 1,
                    name: '群组编辑'
                }
            ],
            groupId: 1,
            timeData: {
                startTime: '',       //开始时间
                endTime: ''          //结束时间
            },
            groupName: '',        //群组名称
            InGroupUser: {       //固定在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: [],
                retainUser: []       //保留
            },
            noGroupUser: {       //固定不在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: [],
                retainUser: []    //保留
            },
            InGroupUserTow: {       //临时在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: [],
                retainUser: []   //保留
            },
            noGroupUserTow: {       //临时不在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: [],
                retainUser: []  //保留
            },
            isPlayLy: false,
            hostId: 0,       //主持人id
            regionType: 1
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryGroup();
        this.getQueryMember();
        this.getQueryMemberTow();
        this.getQueryRegionUnMember();
        this.getQueryRegionUnMemberTow();
    },
    beforeCreate: function () { },
    methods: {
        bindInput(value) {        //不能输入
            this.groupName = regfunc.noCharacter(value);
        },
        // 切换
        bindSwitchGb(id) {
            //编辑显示
            if (this.groupId == id) {

                return
            }
        },
        checkOne(data, tree) {       //在组选中
            console.log(data, tree)
            this.InGroupUser.selectUser = tree.checkedNodes;
        },
        checkTow(data, tree) {     //不在组选中
            // let bool = this.InGroupUser.userArray.some(item => data.User_ID == item.User_ID);
            // if (bool) {
            //     this.$MessageWarning('此成员用户已在固定群组！');
            //     let array = this.InGroupUser.userArray.map(item => item.User_ID);
            //     this.$refs.treeTow.setCheckedKeys(array);
            //     return
            // }
            this.noGroupUser.selectUser = tree.checkedNodes;
        },
        checkThree(data, tree) {
            this.InGroupUserTow.selectUser = tree.checkedNodes;
        },
        checkFour(data, tree) {
            this.noGroupUserTow.selectUser = tree.checkedNodes;
        },
        // 关闭对话框
        gbDialog() {
            this.dialogTableVisible = false;
        },
        getSendOut() {       //发送
            let find = this.$store.state.groupUserData.find(item => item.label == this.groupName);
            if (find && this.Gid != find.Gid) {
                this.$MessageWarning('群组名称已经存在!');
                return
            }
            this.groupEdit(0);
        },
        groupEdit(type) {       //群组编辑
            let that = this;
            if (!regfunc.rGroupName.test(that.groupName)) {
                that.$MessageWarning('群组名称由2-15位数字,中文,英文组成');
                return
            }
            let fixUsers = "";
            let tmpUsers = "";
            let arr = [];
            if (type == 0) {
                fixUsers = that.InGroupUser.retainUser.map(item => item.User_ID).join();
                tmpUsers = that.InGroupUserTow.retainUser.map(item => item.User_ID).join();
            } else if (type == 1) {    //固定群组不在组移除全部
                if (that.InGroupUser.retainUser.length == that.InGroupUser.userArray.length) {
                    fixUsers = "";
                } else {
                    that.InGroupUser.userArray.forEach(item => {
                        that.InGroupUser.retainUser.forEach((r, index) => {
                            if (item.User_ID == r.User_ID) {
                                that.InGroupUser.retainUser.splice(index, 1);
                            }
                        })
                    })
                    fixUsers = that.InGroupUser.retainUser.map(item => item.User_ID).join();
                }
            } else if (type == 2) {    //固定群组不在组移除
                arr = that.InGroupUser.retainUser;
                arr.forEach(() => {
                    that.InGroupUser.selectUser.forEach(item1 => {
                        let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                        if (i >= 0) {
                            arr.splice(i, 1);
                        }
                    })
                })
                fixUsers = arr.map(item => item.User_ID).join();
            } else if (type == 3) {    //固定群组不在组添加
                fixUsers = [...that.InGroupUser.retainUser, ...that.noGroupUser.selectUser].map(item => item.User_ID).join();
            } else if (type == 4) {    //固定群组不在组添加全部
                let arr = JSON.parse(JSON.stringify(that.noGroupUser.userArray));
                arr.forEach(() => {
                    that.InGroupUserTow.retainUser.forEach(item1 => {
                        let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                        if (i >= 0) {
                            arr.splice(i, 1);
                        }
                    })
                })
                arr = that.deweight([...that.InGroupUser.retainUser, ...arr]);
                if (that.InGroupUser.retainUser.length == arr.length) {
                    that.$MessageWarning('此成员用户已在临时群组！');
                    return
                }
                fixUsers = arr.map(item => item.User_ID).join();
            } else if (type == 5) {    //临时群组不在组移除全部
                if (that.InGroupUserTow.retainUser.length == that.InGroupUserTow.userArray.length) {
                    tmpUsers = "";
                } else {
                    that.InGroupUserTow.userArray.forEach(item => {
                        that.InGroupUserTow.retainUser.forEach((r, index) => {
                            if (item.User_ID == r.User_ID) {
                                that.InGroupUserTow.retainUser.splice(index, 1);
                            }
                        })
                    })
                    tmpUsers = that.InGroupUserTow.retainUser.map(item => item.User_ID).join();
                }
            } else if (type == 6) {    //临时群组不在组移除
                arr = that.InGroupUserTow.retainUser;
                arr.forEach(() => {
                    that.InGroupUserTow.selectUser.forEach(item1 => {
                        let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                        if (i >= 0) {
                            arr.splice(i, 1);
                        }
                    })
                })
                tmpUsers = arr.map(item => item.User_ID).join();
            } else if (type == 7) {    //临时群组不在组移除
                tmpUsers = [...that.InGroupUserTow.retainUser, ...that.noGroupUserTow.selectUser].map(item => item.User_ID).join();
            } else if (type == 8) {    //临时群组不在组移除
                arr = JSON.parse(JSON.stringify(that.noGroupUserTow.userArray));
                arr.forEach(() => {
                    that.InGroupUser.retainUser.forEach(item1 => {
                        let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                        if (i >= 0) {
                            arr.splice(i, 1);
                        }
                    })
                })
                arr = that.deweight([...that.InGroupUserTow.retainUser, ...arr]);
                if (that.InGroupUserTow.retainUser.length == arr.length) {
                    that.$MessageWarning('此成员用户已在固定群组！');
                    return
                }
                tmpUsers = arr.map(item => item.User_ID).join();
            }
            if (type != 0 && type < 5) {     //临时
                tmpUsers = that.InGroupUserTow.retainUser.map(item => item.User_ID).join();
            }
            if (type >= 5) {        //固定
                fixUsers = that.InGroupUser.retainUser.map(item => item.User_ID).join();
            }
            let timezoneOffset = -(new Date().getTimezoneOffset()); //时区
            let parmas = {
                regionId: parseInt(that.regionId),
                dspId: parseInt(that.userInfo.User_ID),// 调度员id
                timeOffset: parseInt(timezoneOffset),//时区
                fixUsers: fixUsers,
                tmpUsers: tmpUsers
            };
            if (that.regionId > 0) {
                parmas.regionId = parseInt(that.regionId) //区域id
            }
            if (type == 0) {
                parmas.name = that.groupName; //区域群组名称
            }
            axios.post(`${regionUrlapi}-${that.$store.state.userState.server}/region/update`, qs.stringify(parmas), {
                headers: beasconfig.tokenSl
            })
                .then(res => {
                    if (res.data.code == 200) {
                        if (type == 0) {
                            that.dialogTableVisible = false;
                            that.$MessageSuccess('更新成功');
                        } else if (type != 0 && type < 5) {
                            // if (type == 3) {
                            //     that.noGroupUser.selectUser = [];
                            // } else {
                            //     that.noGroupUser.userArray = [];
                            // }
                            that.InGroupUser.searchName = "";
                            that.noGroupUser.searchName = "";
                            that.noGroupUser.selectUser = [];
                            that.InGroupUser.selectUser = [];
                            that.getQueryMember();
                            that.getQueryRegionUnMember();
                        } else if (type >= 5) {
                            that.InGroupUserTow.searchName = "";
                            that.noGroupUserTow.searchName = "";
                            that.noGroupUserTow.selectUser = [];
                            that.InGroupUserTow.selectUser = [];
                            that.getQueryMemberTow();
                            that.getQueryRegionUnMemberTow();
                        }
                    } else if (res.data.code == 416 && type == 3 || res.data.code == 416 && type == 4 || res.data.code == 416 && type == 7 || res.data.code == 416 && type == 8) { //只有添加才提示
                        that.$MessageWarning('群组添加成员数量超过上限!');
                    } else {
                        that.$MessageWarning(res.data.message);
                    }
                })
        },
        changeGroup(type) {      //固定再组用户添加 删除
            // if(type){

            // }
            let bool = false;
            let bool1 = false;
            if (type == 1 && this.InGroupUser.userArray.length <= 0) {
                this.$MessageWarning('移除失败,固定在组用户列表为空!');
                return
            }
            if (type == 2 && this.InGroupUser.selectUser.length <= 0) {
                this.$MessageWarning('请选择需要移除的用户!');
                return
            }
            if (type == 3 && this.noGroupUser.selectUser.length <= 0) {
                this.$MessageWarning('请选择需要添加的用户!');
                return
            }
            if (type == 4 && this.noGroupUser.userArray.length <= 0) {
                this.$MessageWarning('添加失败,不在组用户列表为空!');
                return
            }
            if (type == 5 && this.InGroupUserTow.userArray.length <= 0) {
                this.$MessageWarning('移除失败,临时在组用户列表为空!');
                return
            }
            if (type == 6 && this.InGroupUserTow.selectUser.length <= 0) {
                this.$MessageWarning('请选择需要移除的用户!');
                return
            }
            if (type == 7 && this.noGroupUserTow.selectUser.length <= 0) {
                this.$MessageWarning('请选择需要添加的用户!');
                return
            }
            if (type == 8 && this.noGroupUserTow.userArray.length <= 0) {
                this.$MessageWarning('添加失败,不在组用户列表为空!');
                return
            }
            if (type == 3) {
                this.noGroupUser.selectUser.forEach(item => {
                    this.InGroupUserTow.retainUser.forEach(itme1 => {
                        if (item.User_ID == itme1.User_ID) {
                            bool = true;
                        }
                    })
                })
            }
            if (type == 7) {
                this.noGroupUserTow.selectUser.forEach(item => {
                    this.InGroupUser.retainUser.forEach(itme1 => {
                        if (item.User_ID == itme1.User_ID) {
                            bool1 = true;
                        }
                    })
                })
            }
            if (bool) {
                this.$MessageWarning('此成员用户已在临时群组！');
                return
            }
            if (bool1) {
                this.$MessageWarning('此成员用户已在固定群组！');
                return
            }
            this.groupEdit(type);
        },
        bindSearch(type) {      //搜索
            if (type == 1) {      //搜索再组成员
                this.InGroupUser.selectUser = [];
                this.getQueryMember(1);
            } else if (type == 2) {  //搜索不再组成员
                this.noGroupUser.selectUser = [];
                this.getQueryRegionUnMember(1);
            } else if (type == 3) {  //搜索不再组成员
                this.InGroupUserTow.selectUser = [];
                this.getQueryMemberTow(1);
            } else if (type == 4) {  //搜索不再组成员
                this.noGroupUserTow.selectUser = [];
                this.getQueryRegionUnMemberTow(1);
            }
        },
        bindEmpty(type) {        //清空输入框值
            if (type == 1) {
                this.InGroupUser.searchName = "";
                this.InGroupUser.selectUser = [];
                this.getQueryMember();
            } else if (type == 2) {
                this.noGroupUser.searchName = "";
                this.noGroupUser.selectUser = [];
                this.getQueryRegionUnMember();
            } else if (type == 3) {
                this.InGroupUserTow.searchName = "";
                this.InGroupUserTow.selectUser = [];
                this.getQueryMemberTow();
            } else if (type == 4) {
                this.noGroupUserTow.searchName = "";
                this.noGroupUserTow.selectUser = [];
                this.getQueryRegionUnMemberTow();
            }
        },
        async getQueryMember(type) {       //搜索再组成员
            let that = this;
            let data = {
                regionId: that.regionId,
                uId: that.userInfo.id,
                type: 0,     //0：固定用户 1：临时用户
                keyword: that.InGroupUser.searchName
            }
            if (that.InGroupUser.searchName != "" && that.InGroupUser.searchName != null) {
                data.keyword = that.InGroupUser.searchName
            }
            await queryRegionMember(data).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.InGroupUser.userArray = res.data.data;
                    if (type != 1) {
                        that.InGroupUser.retainUser = res.data.data;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryRegionUnMember(type) {     //搜索不再组成员
            let that = this;
            let data = {
                regionId: that.regionId,
                uId: that.userInfo.id,
                type: 0,     //0：固定用户 1：临时用户
                keyword: that.noGroupUser.searchName
            }
            queryRegionUnMember(data).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.noGroupUser.userArray = res.data.data;
                    if (type != 1) {
                        that.noGroupUser.retainUser = res.data.data;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        async getQueryMemberTow(type) {       //搜索再组成员
            let that = this;
            let data = {
                regionId: that.regionId,
                uId: that.userInfo.id,
                type: 1,     //0：固定用户 1：临时用户
                keyword: that.InGroupUserTow.searchName
            }
            if (that.InGroupUser.searchName != "" && that.InGroupUser.searchName != null) {
                data.keyword = that.InGroupUser.searchName
            }
            await queryRegionMember(data).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.InGroupUserTow.userArray = res.data.data;
                    if (type != 1) {
                        that.InGroupUserTow.retainUser = res.data.data;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryRegionUnMemberTow(type) {     //搜索不再组成员
            let that = this;
            let data = {
                regionId: that.regionId,
                uId: that.userInfo.id,
                type: 1,     //0：固定用户 1：临时用户
                keyword: that.noGroupUserTow.searchName
            }
            queryRegionUnMember(data).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.noGroupUserTow.userArray = res.data.data;
                    if (type != 1) {
                        that.noGroupUserTow.retainUser = res.data.data;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryGroup() {       //区域详情
            axios.get(`${regionUrlapi}-${this.$store.state.userState.server}/region/${this.regionId}`).then((res) => {
                if (res.data.code == 200) {
                    this.regionType = res.data.data.regionType;
                    this.groupName = res.data.data.regionName;
                    this.efId = res.data.data.regionId;
                    this.pointList = res.data.data.pointList;
                } else {
                    this.$MessageWarning(res.data.message);
                }
            })
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.User_ID != i.User_ID);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr;
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        }
    },
    mounted() {

    },
    filters: {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url(../../css/scrollbar.css);

/deep/.el-dialog {
    background: #0F3343;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }

    .dialog {
        width: 94%;
        background-color: #08222E;
        margin: auto;
        color: #fff;
        border: 1px solid #07415A;

        &_block {
            margin-bottom: 15px;

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            .el-icon-date:before {
                display: none;
            }

            .el-input__inner {
                width: 195px;
                height: 35px;
                background-color: #08222E;
                border: 1px solid #07415A;
                color: #fff;
            }
        }

        &_text {
            font-size: 14px;
        }

        &_left {
            width: 120px;
            height: 100%;
            background-color: #0a2a38;
            border-right: 1px solid #07415A;

            &_groupBg {
                width: 100%;
                height: 75px;
                line-height: 75px;
                text-align: center;
            }

            &_groupBg:first-child {
                border-top: none !important;
            }

            &_bg {
                width: 100%;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #08222E;
                border-top: 1px solid #07415A;
                border-bottom: 1px solid #07415A;
                border-right: 1px solid #08222E;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            margin: auto;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #07415A;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 6px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        // width: 80%;
                        min-height: 40px;
                        background-color: #2e7777;
                        border-radius: 5px;
                        font-size: 14px;

                        &_text {
                            padding: 5px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        min-width: 50px;
                        color: #727171;
                        margin-top: 20px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4AFFFF;
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 92%;
            margin: auto;

            &_top {
                width: 100%;
                height: 75px;
                border-bottom: 1px solid #07415A;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: #08222E;
                    border: 1px solid #07415A;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                font-size: 12px;
                color: red;
            }

            &_input {
                width: 285px;
                height: 40px;
                margin: 0px 10px;
            }

            &_user {
                width: 100%;
                height: 100%;

                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    flex: 1;
                    // display: flex;

                    &_box {
                        width: 280px;
                        // height: 370px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 15px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 180px;
                    border: 1px solid #6e6d6d;
                    overflow: hidden;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                            font-size: 14px !important;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4affff;
                margin-left: 10px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: #4AFFFF;
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

/deep/.el-input.is-disabled .el-input__inner {
    background: transparent;
}

/deep/.el-input__icon {
    display: none;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #08222E;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: #08222E;
    border: 1px solid #2e7777;

    &_input {
        flex: 1;
        height: 100%;
        background-color: #08222E;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #4AFFFF !important;
        background-color: transparent !important;
        color: #4AFFFF !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: #4affff;
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: #4affff;
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        line-height: 28px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

/deep/.el-radio-group {
    display: flex;
    flex-direction: column;

    .el-radio {
        color: #ffffff;
        margin: 0px 10px 5px 10px;
        display: flex;
        align-items: center;

        &>.el-radio__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #4AFFFF;
        background: #4AFFFF;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #4AFFFF;
    }

    .el-radio__inner:hover {
        border-color: #4AFFFF;
    }
}



.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>