const upUserImg = (data) => {
    if (data.isStartSpeech) {  //正在讲话
        if (data.role == 0 && data.online == 3) {
            return require("@/assets/group/zzjhdyh.png");
        }

        if (data.role == 3 && data.online == 3) {
            return require("@/assets/group/zzjh.png");
        }
    } else {
        // 摇闭开始
        if (data.role == 0 && data.online != 3 && !data.audioEnabled) {
            return require("@/assets/group/shakeCloseLx.png");
        }
        // 离线
        if (data.role == 3 && data.online != 3 && !data.audioEnabled) {
            return require("@/assets/group/shakeCloseLx1.png");
        }
        // 在线
        if (data.role == 0 && data.online == 3 && data.Ingroup && !data.audioEnabled) {
            return require("@/assets/group/shakeCloseZx.png");
        }

        if (data.role == 3 && data.online == 3 && data.Ingroup && !data.audioEnabled) {
            return require("@/assets/group/shakeCloseZx1.png");
        }
        // 在线不再组
        if (data.role == 0 && data.online == 3 && !data.Ingroup && !data.audioEnabled) {
            return require("@/assets/group/shakeCloseDdy.png");
        }

        if (data.role == 3 && data.online == 3 && !data.Ingroup && !data.audioEnabled) {

            return require("@/assets/group/shakeCloseDdy1.png");
        }
        // 摇闭结束

        if (data.role == 0 && data.online != 3 && data.audioEnabled) {

            return require("@/assets/group/off-lineUser.png");
        }

        if (data.role == 3 && data.online != 3 && data.audioEnabled) {

            return require("@/assets/group/off-lineDdy.png");
        }

        if (data.role == 0 && data.online == 3 && data.Ingroup && data.audioEnabled) {

            return require("@/assets/group/xzUser.png");
        }

        if (data.role == 0 && data.online == 3 && !data.Ingroup && data.audioEnabled) {

            return require("@/assets/group/bzzUser.png");
        }

        if (data.role == 3 && data.online == 3 && data.Ingroup && data.audioEnabled) {

            return require("@/assets/group/zzDdy1.png");
        }

        if (data.role == 3 && data.online == 3 && !data.Ingroup && data.audioEnabled) {

            return require("@/assets/group/zzDdy.png");
        }
    }
}

const userServeImg = (data) => {  //用户服务图标
    let img = '';
    if (data.services && data.services.soundRecording) {

        img += `<img class="userimgw userimgw_left" src="${require("@/assets/group/soundrecording.png")}" alt="" />`;
    }
    if (data.services && data.services.sos) {

        img += `<img class="userimgw userimgw_left" src="${require("@/assets/group/sos.png")}" alt="" />`;
    }
    if (data.services && data.services.enclosure) {

        img += `<img class="userimgw userimgw_left" src="${require("@/assets/group/enclosure.png")}" alt="" />`;
    }
    if (data.services && data.services.gps) {

        img += `<img class="userimgw userimgw_left" src="${data.GPSswitch ? require("@/assets/group/gps.png") : require("@/assets/group/gps1.png")}" alt="" />`;
    }
    if (data.services && !data.services.gps) {

        img += `<img class="userimgw userimgw_left" src="${data.celllocation_switch ? require("@/assets/group/jwdd.png") : require("@/assets/group/jwdd1.png")}" alt="" />`;
    }

    return img;
}

export default { upUserImg, userServeImg }