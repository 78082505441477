<template>
    <!-- 右侧 -->
    <div class="overall flex_align prohibitCopy">
        <!-- 下拉菜单 -->
        <div class="cdropdown" style="justify-content: space-between">
            <el-dropdown class="flex_align" @command="handleCommand" :hide-on-click="false" trigger="click">
                <div class="el-dropdown-link flex_align">
                    <img src="../assets/user.png" alt="" />
                    <div class="flex_grow" style="color: #ffffff">
                        {{ $store.state.userInfo.nickname }}
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown" style="text-align: center">
                    <el-dropdown-item class="flex_align" command="1">PTT按键显示
                        <el-switch style="margin-left: 15px" v-model="$store.state.userState.isTalkButton"
                            active-color="#4affff" :inactive-value="0" :active-value="1" inactive-color="#838383">
                        </el-switch>
                    </el-dropdown-item>
                    <el-dropdown-item class="flex_align" command="2">SOS告警声音
                        <el-switch style="margin-left: 15px" v-model="$store.state.userState.sosVoice"
                            active-color="#4affff" :inactive-value="0" :active-value="1" inactive-color="#838383">
                        </el-switch>
                    </el-dropdown-item>
                    <el-dropdown-item class="flex_align" command="3">围栏告警声音<el-switch style="margin-left: 15px"
                            v-model="$store.state.userState.enclosureVoice" active-color="#4affff"
                            inactive-color="#838383" :inactive-value="0" :active-value="1">
                        </el-switch>
                    </el-dropdown-item>
                    <el-dropdown-item class="flex_align" command="4">运动轨迹绑路<el-switch style="margin-left: 15px"
                            v-model="$store.state.userState.isBindRoad" :inactive-value="0" :active-value="1"
                            active-color="#4affff" inactive-color="#838383">
                        </el-switch>
                    </el-dropdown-item>
                    <el-dropdown-item command="5">
                        <div style="
                border-top: 1px solid #666666;
                padding: 0px 10px;
                color: #468383;
              ">
                            修改密码
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="6">
                        <div style="
                border-top: 1px solid #666666;
                padding: 0px 10px;
                color: red;
              ">
                            退出登录
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div v-if="$route.meta.navId != 1" class="dataanalysis flex_align" @click="bindDataShow()">
            <div style="margin-right: 10px">数据分析</div>
            <img v-if="$store.state.isDataAnalysis" src="@/assets/navigationbar/dataanalysis.png" alt="" />
            <img v-else src="@/assets/navigationbar/dataanalysis1.png" alt="" />
        </div>
        <!-- 修改密码弹出框 -->
        <popup ref="popupPassword"></popup>
    </div>
</template>

<script></script>
<script>
import { updateUserInfo } from "../administration/dispatchLntercom.js";
import { _throttle } from "@/method/utils.js";
import EventBus from "../eventBus/eventBus.js";
import popup from "@/components/popup.vue";
export default {
  components: {
    popup
  },
  data() {
    return {
      userInfo: {}, //用户数据
      isDataAnalysis:false
    };
  },
  props: {

  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  filters: {},
  methods: {
    bindDataShow() {
      //数据分析显示隐藏
      this.$store.state.isDataAnalysis = !this.$store.state.isDataAnalysis;
    },
    handleCommand(e) {
      //下拉菜单
      if (e < 5) {
        let data = {
          uId: this.$store.state.userInfo.id,
          isBindRoad: this.$store.userState.isBindRoad,
          sosVoice: this.$store.userState.sosVoice,
          speechRecognition: this.$store.userState.speechRecognition,
          fullDuplex: this.$store.userState.fullDuplex,
          isTalkButton: this.$store.userState.isTalkButton,
          enclosureVoice: this.$store.userState.enclosureVoice,
        };
        updateUserInfo(data).then((res) => {
          console.log(res);
        });
      }
      if (e == 5) {
        // 修改密码
        this.$refs.popupPassword.dialogModifyPasswode = true;
      }
      if (e == 6) {
        this.signOutLogin();
      }
    },
    //退出登录
    signOutLogin() {
      let that = this;
      that
        .$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          EventBus.$emit("changePassword");
        })
        .catch(() => {});
    },
  },
  watch: {

  }
};
</script>
<style lang="less" scoped>
.overall {
    position: absolute;
    right: 0px;
    top: 26px;
    right: 10px;
    z-index: 10;
}

.flex_align {
    display: flex;
    align-items: center;
}

// 个人信息
.cdropdown {
    width: 225px;
    background: rgba(8, 108, 118, 0.2);
    border-radius: 6px;
    border: 1px solid #0A7684;

    .el-dropdown {
        width: calc(100% - 36px);
        height: 38px;
        margin: 0px auto;
    }

    .el-dropdown-link {
        width: 100%;
        cursor: pointer;
        color: #00fdff;
        font-size: 16px;

        &>img {
            width: 17px;
            height: 20px;
        }

        .dropdown_xh {
            width: 100%;
            height: 100%;
            border-top: 1px solid #ccc;
        }

        .img {
            width: 16px;
            height: 17px;
            margin-right: 5px;
        }

        div {
            // text-align: right;
            padding: 0px 8px;
            white-space: nowrap;
            /*内容超宽后禁止换行显示*/
            overflow: hidden;
            /*超出部分隐藏*/
            text-overflow: ellipsis;
            /*文字超出部分以省略号显示*/
        }
    }

    .el-icon-arrow-down {
        font-size: 18px;
    }
}

.dataanalysis {
    width: 120px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    margin-left: 17px;
    color: #ffffff;
    background: rgba(8, 108, 118, 0.2);
    border-radius: 6px;
    border: 1px solid #0A7684;
    cursor: pointer;
}
</style>