<template>
    <div class="box flex">
        <!-- 通话 -->
        <div :class="['dh', $route.meta.navId == 1 ? 'dh_left' : '']" v-if="isTelephone && !isOpen">
            <img class="horn1 horn" src="../../assets/video/horn1.png" alt="">
            <img class="horn2 horn" src="../../assets/video/horn2.png" alt="">
            <img class="horn3 horn" src="../../assets/video/horn3.png" alt="">
            <img class="horn4 horn" src="../../assets/video/horn4.png" alt="">
            <div class="dh_box">
                <div class="dh_title">
                    <div class="dh_title_text">对方邀请视频呼叫...</div>
                    <img class="mouse" @click="narrow(0)" src="../../assets/conversation/sx.png" alt="">
                </div>
                <div class="dh_logo mouse">
                    <img src="../../assets/conversation/spIcon2.png" alt="">
                    <div style="margin-top: 15px;">{{ $store.state.videoCallInfo.callName }}</div>
                </div>
                <div class="dh_bottom flex mouse">
                    <img style="margin-right: 20%;" @click="hangup(1)" src="../../assets/conversation/gd.png" alt="">
                    <img @click="answerVideoCall()" src="../../assets/conversation/jt.png" alt="">
                </div>
            </div>
        </div>
        <!-- 视频 -->
        <div v-if="isVideo" :class="['sp', spStyle()]"
            :style="'width:' + clientWidth + 'px;' + 'height:' + clientHeight + 'px;' + hierarchy">
            <img class="horn1 horn" src="../../assets/video/horn1.png" alt="">
            <img class="horn2 horn" src="../../assets/video/horn2.png" alt="">
            <img class="horn3 horn" src="../../assets/video/horn3.png" alt="">
            <img class="horn4 horn" src="../../assets/video/horn4.png" alt="">
            <!-- 连接中 -->
            <div class="sp_box" v-show="isLoading">
                <div class="sp_title mouse">
                    <div class="sp_title_text">连接中...</div>
                    <!-- <div class="sp_title_text">{{ timeData }}</div> -->
                    <img @click="narrow(1)" src="../../assets/conversation/sx.png" alt="">
                </div>
                <div class="sp_logo">
                    <img src="../../assets/conversation/spIcon.png" alt="">
                    <div style="margin-top: 15px;">{{ $store.state.videoCallInfo.callName }}</div>
                </div>
                <div v-if="gdVideoType != 0" class="tips">
                    {{ gdVideoType | tipsText }}
                </div>
                <div class="sp_bottom mouse" @click="hangup(1)">
                    <img src="../../assets/conversation/spGd.png" alt="">
                </div>
            </div>
            <!-- 接听 -->
            <div class="sp_box" v-show="!isLoading">
                <div class="video_tips" v-if="videoTips != '' && !hasMicrophone || videoTips != '' & !hasWebcam">
                    <div class="video_tips_box">
                        <div style="flex: 1;margin-right: 10px;">{{ videoTips }}</div><img @click="closeVideoTips()"
                            style="width: 20px;height: 20px;" src="../../assets/sos/textGb12x.png" alt="">
                    </div>
                </div>
                <div class="sp_box_title flex">
                    <div class="sp_box_title_name">{{ $store.state.videoCallInfo.callName }}</div>
                    <div class="sp_box_title_text">{{ timeData }}</div>
                </div>
                <video autoplay id="selfVideo" @click="switchVideo(1)"
                    :class="videpType === 0 ? 'dfvideo' : 'selfVideo'">
                    Your browser is too old which doesn't support HTML5 video.
                </video>
                <video autoplay :src="$store.state.videoCallInfo.videoPull" @click="switchVideo(0)" id="dfvideo"
                    :class="videpType === 0 ? 'selfVideo' : 'dfvideo'">.
                    Your browser is too old which doesn't support HTML5 video.
                </video>
                <div class="sp_box_tottom mouse">
                    <img @click="hangup(1)" src="../../assets/conversation/videoHangup.png" alt="">
                    <img @click="mute()" v-if="isMute" src="../../assets/conversation/videoVoice.png" alt="">
                    <img @click="mute()" v-else src="../../assets/conversation/videoVoice1.png" alt="">
                </div>
            </div>
        </div>
        <div v-show="isOpen" :class="['xt flex mouse', $route.meta.navId == 1 ? 'dh_left' : '']" @click="narrow(2)">
            <div style="width: 100%;text-align:center;">
                <img class="xt_dh" v-if="type == 0" src="../../assets/conversation/thsx.png" alt="">
                <img class="xt_dh" v-else src="../../assets/conversation/spsx.png" alt="">
                <div>{{ timeData }}</div>
            </div>
        </div>
    </div>
</template>
<script></script>
<script>
import axios from "axios";
import qs from "qs";
import beasconfig from "@/api/beasconfig.js"
const videoUrlapi = beasconfig.videoUrlapi;
import EventBus from "@/eventBus/eventBus.js";
import videoCall from "@/method/wav/video_call.mp3";
export default {
    props: {
        // isTelephone: {      //通话显示
        //     type: Boolean,
        //     default: false
        // },
        // isVideo: {      //视频显示
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            isTelephone: false,       //通话显示
            isVideo: false,           //视频显示
            type: 0,              //显示类型
            isOpen: false,            //电话缩小
            isLoading:true,    //加载中
            timer: '',           //计时
            timeData: '00:00:00',
            videoWidth: 600,
            videoHeight: 600,
            timerOne: null,
            userInfo: null,     //用户信息
            gdVideoType: 0,
            thisVideo: null,
            ppllaayyeerr:null,
            ownPlayer:null,             //调度员推流
            userPlayer:null,            //用户推流
            clientWidth:100,
            clientHeight:800,        //页面高度
            videpType:0,
            isMute:true,
            lsOnCall:null,
            lsOnCallTime:null,
            hierarchy:'z-index: 1;',
            hasMicrophone:false,    //是否有麦克风
            hasSpeakers:false,      //是否有扬声器（喇叭）
            hasWebcam:false,        //是否有摄像头
            videoSuccessPlay:false,  //视频是否成功播放
            videoTips:'',        //提示语
            resolution:{w:480,h:640}
        };
    },
    created() {
        let that = this;
        EventBus.$on("butMute", function () {
            that.mute();
        });
        EventBus.$on("agreeVideoCall", function (type,userId) {        //同意视频通话
            if(type == 200){
                that.startTimeOne();
                that.isTelephone = true;
                that.playAudio(1);
                that.checkDeviceSupport();
            }else if(type == 300){
                that.isVideo = true;
                that.$store.state.videoCallInfo.switchVideo = 0;
                that.start();
            }else if(type == 304){
                that.gdVideoCall('挂断',userId);
            }
        });
        EventBus.$on("gdVideoCall", function (type) {
            that.gdVideoType = type;
            that.gdVideoCall();
        });
    },
    beforeDestroy: function () {
        EventBus.$off("gdVideoCall");
        EventBus.$off("agreeVideoCall");
        EventBus.$off("butMute");
        this.$store.state.videoCallInfo.switchVideo = 0;
        if(this.$store.state.videoCallInfo.callType > 0){
            this.gdVideoType = 0;
            this.returnData();      //防止无网络
            this.gdVideoCall();
        }
        this.endEcho();
    },
    mounted() {
        let that = this;
        that.changeHeight();
        window.addEventListener('resize',function(){        //监听页面高度变化
            // console.log(888888888888888888)
            that.changeHeight();
        })
        navigator.mediaDevices.addEventListener('devicechange', (e) => {        //监听耳机麦克风摄像头是否插入
            that.checkDeviceSupport();
        });
    },
    filters:{       //提示文字
        tipsText: function (id) {
            let array = [
                {
                    id:301,
                    text:'对方已拒绝'
                },
                {
                    id:302,
                    text:'对方已挂断'
                },
                {
                    id:303,
                    text:'对方未接听'
                },
                {
                    id:304,
                    text:'对方忙线!'
                }
            ]
            let find = array.find(item => item.id == id);
            
            return find.text;
        }
    },
    methods: {
        spStyle(){
            let navId = this.$route.meta.navId;
            let css = "";
            if(navId != 1){
                css = 'mleft';
            }else{
                css = this.$store.state.isLeftHome && !this.$store.state.isRightHome ? 'mleft': !this.$store.state.isLeftHome && this.$store.state.isRightHome?'mright':'';
            }
            return css;
        },
        closeVideoTips(){       //关闭提示
            this.videoTips = "";
        },
        checkDeviceSupport(){    //查询是否有开启摄像头
            let that = this;
            that.videoTips = '';
            that.hasMicrophone = false; //是否有麦克风
            that.hasSpeakers = false;   //是否有扬声器（喇叭）
            that.hasWebcam = false;  //是否有摄像头
            navigator.mediaDevices.getUserMedia({audio: false, video: true}).then(stream => {       //video: true 单独查询电脑是否自带摄像头
                stream.getTracks().forEach(track => track.stop());
                that.checkDeviceSupportTow('video');
            }) .catch(error => { console.log('Error :', error) });
            navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(stream => {       //audio: true 单独查询电脑是否有麦克风
                stream.getTracks().forEach(track => track.stop());
                that.checkDeviceSupportTow('audio');
            }) .catch(error => { console.log('Error :', error) });
        },
        checkDeviceSupportTow(name){
            let that = this;
            var callback = "";        
            if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
                // Firefox 38+ seems having support of enumerateDevicesx
                navigator.enumerateDevices = function(callback) {
                    navigator.mediaDevices.enumerateDevices().then(callback);
                };
            }
            // var canEnumerate = false;
            // if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
            //     canEnumerate = true;
            // } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
            //     canEnumerate = true;
            // }
            var isMicrophoneAlreadyCaptured = false;
            var isWebcamAlreadyCaptured = false;
		    // if (!canEnumerate) {
		    //     return;
		    // }
		    // if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
		    //     navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
		    // }
		    // if (!navigator.enumerateDevices && navigator.enumerateDevices) {
		    //     navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
		    // }
		    // if (!navigator.enumerateDevices) {
		    //     if (callback) {
		    //         callback();
		    //     }
		    //     return;
		    // }
		    MediaDevices = [];
		    navigator.enumerateDevices(function(devices) {
		        devices.forEach(function(_device) {
		            var device = {};
		            for (var d in _device) {
		                device[d] = _device[d];
		            }
		            if (device.kind === 'audio') {
		                device.kind = 'audioinput';
		            }

		            if (device.kind === 'video') {
		                device.kind = 'videoinput';
		            }

		            var skip;
		            MediaDevices.forEach(function(d) {
		                if (d.id === device.id && d.kind === device.kind) {
		                    skip = true;
		                }
		            });

		            if (skip) {
		                return;
		            }

		            if (!device.deviceId) {
		                device.deviceId = device.id;
		            }

		            if (!device.id) {
		                device.id = device.deviceId;
		            }

		            if (!device.label) {
		                device.label = 'Please invoke getUserMedia once.';
		                if (!isHTTPs) {
		                    device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
		                }
		            } else {
		                if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
		                    isWebcamAlreadyCaptured = true;
		                }

		                if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
		                    isMicrophoneAlreadyCaptured = true;
		                }
		            }

		            if (device.kind === 'audioinput' && name == "audio") {
		                that.hasMicrophone = true;
		            }

		            if (device.kind === 'audiooutput' && name == "audio" ) {
		                that.hasSpeakers = true;
		            }

		            if (device.kind === 'videoinput' && name == "video") {
                        that.hasWebcam = true;
		            }
		            MediaDevices.push(device);
                    let text = '摄像头，麦克风';
                    if(!that.hasMicrophone && !that.hasWebcam){
                        text = '摄像头，麦克风';
                    }else if(!that.hasMicrophone){
                        text = '麦克风';
                    }else if(!that.hasWebcam){
                        text = '摄像头';
                    }
                    that.videoTips = `未检测到${text}请检查设备`;
		        });
                    if (callback) {
                        callback();
                    }
                });
        },
        playAudio(type){        //播放音频
            if(this.lsOnCall != null){
                this.lsOnCall.pause();
                this.lsOnCall = null;
            }
           if(type == 1){
            this.lsOnCall = new Audio(videoCall);
            this.lsOnCall.play();
            this.lsOnCallTime =  setInterval(()=>{
                this.lsOnCall = new Audio(videoCall);
                this.lsOnCall.play();
            },5000)
           }else{
             clearInterval(this.lsOnCallTime);
             this.lsOnCallTime = null;
           }
        },
        changeHeight(){
            this.clientHeight = Math.round(document.documentElement.clientHeight - 80 - 60 - 125);
            this.clientWidth = Math.round(this.clientHeight * 0.75);
        },
        mute(){ //视频静音
            if(this.isVideo){
                // let selfVideo = document.getElementById('selfVideo');
                // selfVideo.muted = !selfVideo.muted;
                let dfvideo = document.getElementById('dfvideo');
                dfvideo.muted = !dfvideo.muted;
                this.isMute = !this.isMute;
            }
        },
        muteTow(){  //视频静音
            if(this.isVideo && !this.isLoading){
                    // let selfVideo = document.getElementById('selfVideo');
                    let dfvideo = document.getElementById('dfvideo');
                    if(selfVideo && dfvideo){
                        if (this.$store.state.videoCallInfo.isMute) {   //开启
                            // selfVideo.muted = false;
                            dfvideo.muted = false;
                            this.isMute = true;
                        }else{      //静音
                            // selfVideo.muted = true;
                            dfvideo.muted = true;
                            this.isMute = false;
                        }
                    }
            }
        },
        start_play(){       //自己
                let that = this;
                // if(that.$store.state.videoCallInfo.isMute){        //当视频页面静音的情况下
                //     // that.mute();
                //     that.muteTow();
                // }
                // console.log(that.hasWebcam,that.hasMicrophone)
                if(!that.hasMicrophone && !that.hasWebcam){       //当音频和摄像头都没有的情况就不推流
                    that.jsTime();
                    that.jsTimeOne();
                    that.isLoading = false;
                    that.startTime();
                    return
                }
                that.ownPlayer = new ZLMRTCClient.Endpoint(
                        {
                            element: document.getElementById('selfVideo'),// video 标签
                            debug: true,// 是否打印日志
                            zlmsdpUrl:that.$store.state.videoCallInfo.videoPush,//流地址
                            simulcast:false,
                            useCamera:true, //开启摄像头
                            audioEnable:that.hasMicrophone,
                            videoEnable:that.hasWebcam,
                            recvOnly:false,
                            resolution: that.resolution ,//{w:480,h:640},
                            usedatachannel:false,
                        }
                    );
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,function(e)
                    {// ICE 协商出错
                        console.log('ICE 协商出错')
                    });
            
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,function(e)
                    {//获取到了远端流，可以播放
                        console.log('播放成功',e.streams)
                    });
            
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,function(e)
                    {// offer anwser 交换失败
                        console.log('offer anwser 交换失败',e)
                        // that.stop();
                    });
            
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,function(s)
                    {// 获取到了本地流
                        that.jsTime();
                        that.jsTimeOne();
                        that.isLoading = false;
                        that.startTime();
                        document.getElementById('selfVideo').srcObject=s;
                        document.getElementById('selfVideo').muted = true;  //自己的声音静音
                        document.getElementById('selfVideo').play(s)
                    });

                    that.ownPlayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,function(s)
                    {// 获取本地流失败
                    // that.$MessageWarning(`请检查是否开启摄像头！`);
                        that.gdVideoCall('失败');
                        // console.log('获取本地流失败')
                    });

                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,function(state)
                    {// RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
                    console.log('当前状态==>',state)
                    });

                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,function(event)
                    {
                    console.log('rtc datachannel 打开 :',event)
                    });

                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,function(event)
                    {
                    console.log('rtc datachannel 消息 :',event.data)
                    // document.getElementById('msgrecv').value = event.data
                    });
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,function(event)
                    {
                    console.log('rtc datachannel 错误 :',event)
                    });
                    that.ownPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,function(event)
                    {
                    console.log('rtc datachannel 关闭 :',event)
                    });
        },
        start_play1(){      //播放的用户
                let that = this;
                that.videoSuccessPlay = false;
                that.userPlayer = new ZLMRTCClient.Endpoint(
                        {
                            element: document.getElementById('dfvideo'),// video 标签
                            debug: true,// 是否打印日志
                            zlmsdpUrl:that.$store.state.videoCallInfo.videoPull,//流地址
                            simulcast:false,
                            // useCamera:true,
                            useCamera:false,
                            audioEnable:true,
                            videoEnable:true,
                            recvOnly:true,
                            resolution:that.resolution,
                            usedatachannel:false,
                        }
                    );
                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,function(e)
                    {// ICE 协商出错
                        // console.log('ICE 协商出错')
                        that.gdVideoCall('失败');
                    });
            
                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,function(e)
                    {//获取到了远端流，可以播放
                        // console.log('播放成功',e.streams)
                        that.videoSuccessPlay = true;
                    });
            
                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,function(e)
                    {// offer anwser 交换失败
                        console.log('offer anwser 交换失败',e);
                        if(that.videoSuccessPlay){      //链接失败直接关闭
                            that.stop();
                            that.gdVideoCall('失败');
                        }
                    });
            
                    // that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,function(s)
                    // {// 获取到了本地流

                    // });

                    // that.userPlayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,function(s)
                    // {// 获取本地流失败
            
                    // console.log('获取本地流失败')
                    // });

                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,function(state)
                    {// RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
                        // console.log('当前状态==>',state)
                        if(state == "failed" && that.videoSuccessPlay && that.$route.name != "Login" && that.$store.state.videoCallInfo.isAnswerVideo){      //链接失败直接关闭
                            that.returnData();
                            that.$MessageWarning('连接中断,通话结束!');
                        }
                    });

                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,function(event)
                    {
                    console.log('rtc datachannel 打开 :',event)
                    });

                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,function(event)
                    {
                    console.log('rtc datachannel 消息 :',event.data)
                    // document.getElementById('msgrecv').value = event.data
                    });
                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,function(event)
                    {
                        //  console.log('rtc datachannel 错误 :',event)
                         that.gdVideoCall('失败');
                    });
                    that.userPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,function(event)
                    {
                        //  console.log('rtc datachannel 关闭 :',event)
                         that.gdVideoCall('失败');
                    });
        },
        start(){
            let that = this;
            if(that.$route.name == "videoInfo"){
                that.hierarchy = "z-index: 1;";
            }
            that.$store.state.videoCallInfo.isAnswerVideo = true;
            that.playAudio(2);
            that.stop();
            setTimeout(()=>{
                 //480是宽的分辨率  640是高的分辨率
                ZLMRTCClient.isSupportResolution(480,640).then(e=>{   //有些电脑不支持宽小于高所以先验证宽高是否支持不支持则相反
                    that.start_play();
                    that.start_play1();
                }).catch(e=>{ 
                    that.resolution = {w:640,h:480};
                    that.start_play();
                    that.start_play1();
                //   alert("not support resolution")  
                }); 
                // that.start_play();
                // that.start_play1();
            },500)
        },
        stop() {
            if(this.ownPlayer && this.userPlayer)  {
                this.ownPlayer.close();
                this.userPlayer.close();
                this.ownPlayer = null;
                this.userPlayer = null;
                var remote = document.getElementById('dfvideo');
                if(remote) {
                    remote.srcObject = null;
                    remote.load();
                }
                var local = document.getElementById('selfVideo');
                if(local){
                    local.srcObject = null;
                    local.load();
                }
            }
        },
        openPhone(type) {        //打开通话弹出框
            if (type == 0) {
                this.isTelephone = true;
            } else {
                this.isVideo = true;
                this.startTimeOne();
                this.playAudio(1);
            }
            this.checkDeviceSupport();
        },
        switchVideo(type){      //切换放大页面
            if(this.videpType == type){

                return
            }
            this.videpType = type;
        },
        narrow(type) {       //缩小 放大
            if(this.$route.name == "videoInfo" && this.$store.state.videoCallInfo.callType > 0){ //videoCallInfo.callType 4被呼叫
                if (type == 2) {
                    this.isOpen = false;
                    if(this.$store.state.videoCallInfo.isAnswerVideo){  //判断等于true的时候直接显示视频呼叫
                        this.hierarchy = "z-index: 1;";
                        this.$store.state.videoCallInfo.switchVideo = 0;
                    }else{
                        if (this.type == 0) {
                            this.isTelephone = true;
                            this.isVideo = false;
                        } else {
                            this.isTelephone = false;
                            this.isVideo = true;
                        }
                    }
                } else {
                    this.type = type;
                    this.isOpen = true;
                    this.isTelephone = false;
                    this.isVideo = false;
                }
            }else{
                if(this.$store.state.videoCallInfo.callType == 1){
                    this.isVideo = true;
                    this.isOpen = false;
                    this.isTelephone = false;
                }else{
                    this.isOpen = false;
                    this.isTelephone = true;
                }
                this.hierarchy = "z-index: 1;";
            }
            if(this.$route.name != "videoInfo" && this.$store.state.videoCallInfo.callType != 4){
                this.$router.push('/promotion/videoInfo');
            }
        },
        hangup() {       //挂断电话
            this.gdVideoType = 0;
            this.gdVideoCall();
            this.isTelephone = false;
            this.isVideo = false;
        },
        startTime() {        //开始计时
            let that = this;
            let n_sec = 0;
            let n_min = 0;
            let n_hour = 0;
            that.jsTime();
            function timer() {
                return setInterval(() => {
                    var str_sec = n_sec;
                    var str_min = n_min;
                    var str_hour = n_hour;
                    if (n_sec < 10) {
                        str_sec = "0" + n_sec;
                    }
                    if (n_min < 10) {
                        str_min = "0" + n_min;
                    }
                    if (n_hour < 10) {
                        str_hour = "0" + n_hour;
                    }
                    that.timeData = str_hour + ":" + str_min + ":" + str_sec;
                    n_sec++;
                    if (n_sec > 59) {
                        n_sec = 0;
                        n_min++;
                    }
                    if (n_min > 59) {
                        n_sec = 0;
                        n_hour++;
                    }
                }, 1000)
            }
            that.timer = timer();
        },
        jsTime() {      //结束计时
            if(this.timer != null){
                clearInterval(this.timer)
                this.timer = "";
                this.timeData = '00:00:00';
                this.isTelephone = false;
            }
        },
        startTimeOne() {        //60s没人接直接挂断
            let that = this;
            that.jsTimeOne();
            if (that.timerOne == null) {
                that.startTime();
                that.timerOne = setTimeout(() => {
                    if(that.$store.state.videoCallInfo.callType == 1){
                        that.gdVideoType = 303;
                        that.gdVideoCall();
                        that.jsTime();
                    }
                }, 61000);
            }
        },
        jsTimeOne() {      //结束计时
            if(this.timerOne != null){
                clearTimeout(this.timerOne)
                this.timerOne = null;
                this.isTelephone = false;
            }
        },
        answerVideoCall(){      //接听视频
            let that = this;
            that.isTelephone = false;
            let parmas = {
                fromUid: that.$store.state.userInfo.User_ID,
                toUids: that.$store.state.videoCallInfo.userId,
                type: 300,       //300 接听
                recordId:that.$store.state.videoCallInfo.recordId       //消息记录 ID
            };
            axios.post(`${videoUrlapi}-${that.$store.state.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                {
                    headers: beasconfig.tokenSl
                }
            ).then(res => {
                if (res.data.code == 200) {
                    that.isVideo = true;
                    that.isOpen = false;       //电话缩小
                    that.$store.state.videoCallInfo.callType = 2;
                    that.$store.state.videoCallInfo.switchVideo = 0;
                    that.start();
                    if(that.$route.name != "videoInfo"){
                        that.$router.push('/promotion/videoInfo');
                    }
                }else{
                    that.$MessageWarning('接听失败');
                    that.isOpen = true;
                    // that.isTelephone = false;
                    that.isVideo = false;
                    that.returnData();
                }
            })
        },
        gdVideoCall(name,userId) {     //挂断视频
            let that = this;
            let parmas = {
                fromUid: that.$store.state.userInfo.User_ID,
                toUids: userId?userId:that.$store.state.videoCallInfo.userId,
                type:userId?304:302       //302挂断视频
            };
            if(!userId){
                that.jsTime();
                that.jsTimeOne();
            }
            if(that.gdVideoType == 302 || that.gdVideoType == 301 || that.gdVideoType == 304){
                if(that.gdVideoType == 302){
                    that.$MessageWarning('对方已挂断!');
                }
                that.returnData();
            }else{
                
                        axios.post(`${videoUrlapi}-${that.$store.state.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                    {
                        headers: beasconfig.tokenSl
                    }
                ).then(res => {
                    // console.log(res)
                    if (res.data.code == 200) {
                        if(name == "失败" && that.$store.state.videoCallInfo.callType > 0){
                            that.$MessageWarning('连接失败');
                            // return
                        }
                        if(name == "失败" && that.$store.state.videoCallInfo.callType < 0){
                            return
                        }
                        if (userId) {
                            
                            return
                        }
                        
                        if (that.gdVideoType == 303 && that.$store.state.videoCallInfo.callType == 4) {
                            that.$MessageWarning('对方未接听');
                        }
                        that.returnData();
                    }
                })
            }
        },
        endEcho(){      //结束所有回传
            let videoList = sessionStorage.getItem("videoList");
            if(videoList && videoList.length > 0){
                videoList = JSON.parse(videoList);
                var toUids = videoList.map(item => item.userId).join();
                let parmas = {      //关闭强拉视频
                    fromUid: this.$store.state.userInfo.User_ID,
                    toUids: toUids,
                    type: 302,      //302结束回传
                    data:2          //2是关闭强拉
                };
                axios.post(`${videoUrlapi}-${this.$store.state.userState.server}/rtc/msg/send`, qs.stringify(parmas),
                    {
                        headers: beasconfig.tokenSl
                    }
                ).then(res => {
                    console.log(res)
                })
            }
            sessionStorage.removeItem("videoList");
        },
        returnData(){       //清空视频状态
            this.playAudio(2);
            setTimeout(()=>{
                this.stop();
                this.ownPlayer = null;
                this.userPlayer = null;
                this.isTelephone = false;       //通话显示
                this.isVideo = false;       //视频显示
                this.type = 0;       //显示类型
                this.isOpen = false;       //电话缩小
                this.isLoading = true;       //加载中
                this.videpType = 0;
                this.$store.state.videoCallInfo.callType = 0;
                this.hierarchy = "z-index: 1;";
                this.gdVideoType = 0;
                this.isMute = true;
                this.$store.state.videoCallInfo.isAnswerVideo = false;
            },2000)
        }
    },
    watch: {
        //监听事件
        // "$store.state.videoCallInfo.isMute"() {
        //     this.muteTow();
        // },
        "$store.state.videoCallInfo.switchVideo"(type) {
            if(this.$route.name == "videoInfo" && this.$store.state.videoCallInfo.callType > 0 && this.$store.state.videoCallInfo.isAnswerVideo){
                if(type == 1){
                    this.isOpen = true;
                    this.hierarchy = "z-index: -5;";
                    this.isTelephone = false;
                }else{
                    this.hierarchy = "z-index: 1;";
                    if(this.$store.state.videoCallInfo.isAnswerVideo){
                        this.isOpen = false;
                        this.isTelephone = false;
                    }
                }
            }
        },
        "$store.state.isBroadcast"(bool) {        //语音广播
            //对数据执行操作
            if (bool == true) {
                this.playAudio(2);
            }
        },
        $route(){
            if(this.$route.name != "videoInfo" && this.$store.state.videoCallInfo.callType != 0){
                this.isOpen = true;
                this.hierarchy = "z-index: -5;";
                this.isTelephone = false;
            }else if(this.$route.name == "videoInfo" && this.$store.state.videoCallInfo.callType != 0){
                this.hierarchy = "z-index: 1;";
                if(this.$store.state.videoCallInfo.isAnswerVideo){
                    this.isOpen = false;
                    this.isTelephone = false;
                }
                // else{
                //     this.isOpen = false;
                //     this.isTelephone = true;
                // }
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.dfvideo {
    background-color: #333333 !important;
}

.box {
    color: #fff;
    position: absolute;

    .dh_left {
        left: 440px !important;
    }

    .dh {
        width: 240px;
        height: 400px;
        background-color: #033542;
        border-radius: 5px;
        overflow: hidden;
        position: fixed;
        bottom: 20px;
        left: 405px;
        z-index: 1000;

        &_box {
            width: 100%;
            height: 100%;
            background: #073A46;
            margin: 0px auto;
            font-size: 12px;
            position: relative;
        }

        &_title {
            width: 100%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0px;
            position: absolute;
            top: 0px;
            // left: 0px;
            right: 0px;

            &_text {
                flex: 1;
                text-align: center;
            }

            img {
                width: 17px;
                height: 17px;
                position: absolute;
                right: 10px;
                z-index: 10;
            }
        }

        &_logo {
            width: 100%;
            text-align: center;
            font-size: 16px;
            position: absolute;
            top: 10vh;

            img {
                width: 70px;
                height: 70px;
            }
        }

        &_bottom {
            width: 100%;
            position: absolute;
            bottom: 25px;
            left: 0px;
            justify-content: center;

            img {
                width: 44px;
                height: 44px;
            }
        }
    }

    .mleft {
        margin-left: 202px;
    }

    .mright {
        margin-left: -202px;
    }

    .sp {
        width: 512px;
        height: 800px;
        background-color: #333333;
        border-radius: 5px;
        overflow: hidden;
        position: fixed;
        bottom: 150px;
        left: 50%;
        transform: translate(-50%);
        box-shadow: 0 0px 10px 0 #ffffff;
        z-index: 10;

        &_box {
            width: 100%;
            height: 100%;
            background: #073A46;
            // background: url("../../assets/group/leftBg1.png");
            // background-size: 100% 100%;
            margin: 0px auto;
            color: #fff;
            font-size: 12px;
            position: relative;

            &_title {
                width: 100%;
                font-size: 15px;
                position: absolute;
                top: 10px;
                left: 0px;
                z-index: 5;
                // right: ;

                &_name {
                    margin-left: 15px;
                }

                &_text {
                    width: 100%;
                    position: absolute;
                    left: 0px;
                    text-align: center;
                    font-size: 15px;
                }
            }

            &_tottom {
                width: 100%;
                height: 60px;
                position: absolute;
                bottom: 30px;
                left: 0px;
                z-index: 5;

                &>img:last-child {
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    right: 50px;
                    top: 0px;
                }

                &>img:first-child {
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    left: 50px;
                    top: 0px;
                }
            }

            .selfVideo {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 1;
            }

            .dfvideo {
                width: 112.5px;
                height: 150px;
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 2;
            }
        }

        &_title {
            width: 90%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0px;

            &_text {
                flex: 1;
                text-align: center;
                font-size: 16px;
            }

            img {
                width: 17px;
                height: 17px;
            }
        }

        &_logo {
            width: 100%;
            text-align: center;
            font-size: 24px;
            // margin: 100px 0px 140px 0px;
            margin: 20% 0px 30% 0px;
            position: relative;

            img {
                width: 120px;
                height: 120px;
            }
        }

        &_bottom {
            width: 100%;
            text-align: center;

            img {
                width: 61px;
                height: 61px;
            }
        }
    }

    .xt {
        width: 65px;
        height: 65px;
        position: fixed;
        bottom: 90px;
        left: 405px;
        background-color: #033542;
        font-size: 12px;
        border-radius: 10px;
        z-index: 1000;

        &_dh {
            width: 28px;
            height: 24px;
        }
    }
}

.horn {
    width: 13px;
    height: 12px;
}

.horn1 {
    position: absolute;
    left: -2px;
    top: -1px;
    z-index: 5;
}

.horn2 {
    position: absolute;
    right: -2px;
    top: -1px;
    z-index: 5;
}

.horn3 {
    position: absolute;
    left: -2px;
    bottom: -1px;
    z-index: 5;
}

.horn4 {
    position: absolute;
    right: -2px;
    bottom: -1px;
    z-index: 5;
}

.tips {
    color: red;
    font-size: 25px;
    margin: 25px 0px;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    // opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid red;
}

.video_tips {
    width: 100%;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0px;
    z-index: 5;

    &_box {
        width: 70%;
        margin: auto;
        background: #073A46;
        padding: 15px 20px;
        border-radius: 5px;
        font-size: 15px;
        position: relative;
        display: flex;
        align-items: center;
    }
}

.flex {
    display: flex;
    align-items: center;
}
</style>