<template>
    <!-- <div class="box flex" v-if="dialogTableVisible"> -->
    <el-dialog :title="dialogTitle ? dialogTitle : '编辑'" :append-to-body="true" :before-close="gbDialog" :width="width"
        :visible.sync="dialogTableVisible" center>
        <!-- 广播 -->
        <div class="dialog flex_between" v-if="openGb" style="height: 505px;">
            <div class="dialog_left">
                <div class="dialog_left_bg mouse prohibitCopy"
                    :class="wideBroadcastId == item.id ? 'dialog_left_bgOne' : ''" v-for="item in wideBroadcast"
                    :key="item.id" @click="bindSwitchGb(item.id, 1)">
                    {{ item.name }}
                </div>
            </div>
            <div class="dialog_right">
                <div class="dialog_right_top" ref="cssTop">
                    <template v-if="wideBroadcastId == 1">
                        <div class="dialog_right_top_dh" v-for="(item, index) in broadcastRecordList" :key="index">
                            <div class="dialog_right_top_dh_left"></div>
                            <div class="dialog_right_top_dh_right flex">
                                <div class="dialog_right_top_dh_right_text">{{ item.MSG }}</div>
                            </div>
                            <!-- YYYY- :ss-->
                            <div class="dialog_right_top_dh_text">{{ $moment(item.SEND_TIME).format("MM-DD HH:mm") }}
                                已发送
                            </div>
                        </div>
                    </template>
                    <div v-else ref="timeMsg" style="width:100%;">
                        <div class="dialog_right_top_dh mouse" v-for="(item, index) in broadcastRecordList"
                            :key="index">
                            <div class="dialog_right_top_dh_left"></div>
                            <div class="dialog_right_top_dh_rightOne flex" :style="gbTimeOne(item.MSG)"
                                @click="playLyOne(item, index)">
                                <img v-if="item.bool" src="@/assets/gb/yy1.gif" alt="">
                                <img v-else src="../../assets/gb/yy.png" alt=""> <span>{{ item.MSG | gbTime }} "</span>
                            </div>
                            <!-- YYYY- :ss -->
                            <div style="margin-top: 35px;" class="dialog_right_top_dh_text">
                                {{ $moment(item.SEND_TIME).format("MM-DD HH:mm") }} 已发送</div>
                        </div>
                    </div>
                </div>
                <div class="dialog_right_bottom" v-if="wideBroadcastId == 1">
                    <el-input type="textarea" @focus="focus" @blur="blur" maxlength="50"
                        placeholder="请输入文字广播内容（不超过50字）..." v-model="textarea" suffix-icon="false">
                    </el-input>
                </div>
                <div v-else class="dialog_right_ly mouse">
                    <div class="dialog_right_img" v-if="soundRecordingTime == 0" @mousedown="soundRecording(1)"
                        @mouseup="soundRecording(2)">
                        <span>{{ isSoundRecording ? '正在录音中~' : '点击录制语音广播' }} </span>
                    </div>
                    <div class="dialog_right_img" v-else @click="playLy()">
                        <template v-if="isPlayLy">
                            <img src="../../assets/gb/lzz.png" alt="">
                            <span style="margin-left: 10px;">{{ soundRecordingTime | rounding }} "</span>
                        </template>
                        <template v-else>
                            <img style="height: 30px;" src="../../assets/gb/lzz1.gif" alt="">
                        </template>
                    </div>
                    <div class="dialog_right_delet" v-if="soundRecordingTime > 0" @click="deleteLy">删除</div>
                </div>
            </div>
        </div>
        <!-- 广播结束 -->
        <!-- 选择时间 -->
        <div class="dialog flex_direction" style="height: 165px;" v-if="timeShow">
            <div class="dialog_block dialog_text">
                <span class="demonstration">开始时间 : </span>
                <el-date-picker v-model="timeData.startTime" type="datetime" :picker-options="pickerOptions"
                    placeholder="请选择日期时间">
                </el-date-picker>
            </div>
            <div class="dialog_block dialog_text">
                <span class="demonstration">结束时间 : </span>
                <el-date-picker v-model="timeData.endTime" type="datetime" :picker-options="pickerOptions"
                    placeholder="请选择结束日期">
                </el-date-picker>
            </div>
        </div>
        <!-- 选择时间结束 -->
        <!-- 编辑 -->
        <div class="dialog flex_between" style="height: 505px;" v-if="bgShow">
            <div class="dialog_left mouse">
                <div class="dialog_left_groupBg prohibitCopy" :class="groupId == item.id ? 'dialog_left_bgOne' : ''"
                    v-for="item in groupArray" :key="item.id" @click="bindSwitchGb(item.id, 2)">
                    {{ item.name }}
                </div>
            </div>
            <div class="dialog_right">
                <div class="dialog_group" v-if="groupId == 1">
                    <div class="dialog_group_top flex">
                        <div>群组名称 ：</div>
                        <div class="dialog_group_input">
                            <el-input v-model.trim="groupName" @focus="focus" @blur="blur" @input="bindInput"
                                maxlength="15" placeholder="请输入群组名称">
                            </el-input>
                        </div>
                        <div class="dialog_group_red">*由2-15位数字,中文,英文组成</div>
                    </div>
                    <!-- 开始 -->
                    <div class="dialog_group_user flex_between">
                        <div class="dialog_group_user_text">
                            <div>
                                选择用户 :
                            </div>
                            <div class="dialog_group_red" style="margin-top: 10px;">
                                *编辑即生效
                            </div>
                        </div>
                        <div class="dialog_group_user_xz">
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_xz_box_title">在组用户</div>
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex">
                                        <div class="search_input flex">
                                            <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                            <!-- reserve-keyword -->
                                            <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                                placeholder="用户名称/IMEI">
                                            </el-input>
                                            <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png"
                                                alt="" />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(1)">
                                            <img src="@/assets/group/search.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <el-tree @check="checkOne" :data="InGroupUser.userArray" show-checkbox
                                            node-key="id" default-expand-all :expand-on-click-node="false">
                                        </el-tree>
                                    </div>
                                </div>
                            </div>
                            <div style="width:40px;" class="dialog_group_user_but">
                                <el-tooltip content="全部移除" placement="top">
                                    <img class="mouse" src="@/assets/group/wholemove.png" @click="changeGroup(1)"
                                        alt="">
                                </el-tooltip>
                                <el-tooltip content="移除选中的用户" placement="top">
                                    <img class="mouse" src="@/assets/group/moveuser.png" @click="changeGroup(2)" alt="">
                                </el-tooltip>
                                <el-tooltip content="添加选中的用户" placement="top">
                                    <img class="mouse" src="@/assets/group/adduser.png" @click="changeGroup(3)" alt="">
                                </el-tooltip>
                                <el-tooltip content="全部添加" placement="top">
                                    <img class="mouse" src="@/assets/group/wholeadd.png" @click="changeGroup(4)" alt="">
                                </el-tooltip>
                            </div>
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_xz_box_title">不在组用户</div>
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex">
                                        <div class="search_input flex">
                                            <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                            <!-- reserve-keyword -->
                                            <el-input v-model="noGroupUser.searchName" @focus="focus" @blur="blur"
                                                placeholder="用户名称/IMEI">
                                            </el-input>
                                            <img @click="bindEmpty(2)" class="mouse" src="@/assets/group/gb.png"
                                                alt="" />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(2)">
                                            <img src="@/assets/group/search.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <el-tree :data="noGroupUser.userArray" @check="checkTow" show-checkbox
                                            node-key="id" default-expand-all :expand-on-click-node="false">
                                        </el-tree>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 结束 -->
                </div>
                <!-- 开始 -->
                <div style="width:100%;height:100%;overflow: auto;" v-if="groupId == 2">
                    <div class="dialog_group">
                        <div class="dialog_group_top flex">
                            <div style="width:415px;">
                                <!-- 搜索 -->
                                <div class="search_tow flex">
                                    <div class="search_tow_input flex">
                                        <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                        <!-- reserve-keyword -->
                                        <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                            placeholder="用户/IMEI"></el-input>
                                        <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt="" />
                                    </div>
                                    <div class="search_tow_but mouse" @click="bindSearch(1)">
                                        <img src="@/assets/group/search.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog_group_user_gl">
                            <div class="dialog_group_user_gl_box" style="flex: 1;">
                                <div class="dialog_group_user_gl_box_title">用户名称</div>
                                <div style="margin-bottom:10px;border: 1px solid #383838;"
                                    class="dialog_group_user_gl_box_content ellipsis_app"
                                    v-for="item in InGroupUser.userArray" :key="item.id">
                                    {{ item.label }}
                                </div>
                            </div>
                            <div class="dialog_group_user_gl_box">
                                <div class="dialog_group_user_gl_box_title">用户优先级</div>
                                <div style="margin-bottom:10px;"
                                    class="dialog_group_user_gl_box_content dialog_group_user_gl_box_contentOne"
                                    v-for="item in InGroupUser.userArray" :key="item.id">
                                    <el-select v-model="item.priorities" placeholder="请选择"
                                        @change="changeUserAdministration" @click.native="prioritiesbtn(item.User_ID)">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="dialog_group_user_gl_box">
                                <div class="dialog_group_user_gl_box_title" style="color:red;">*编辑立即生效</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 结束 -->
                <!-- 开始 -->
                <div class="dialog_group" v-if="groupId == 3">
                    <div class="dialog_group_top flex">
                        <div>主持人 ：</div>
                        <div class="dialog_group_input dialog_group_zcr">
                            <el-input :disabled="true" @focus="focus" @blur="blur" v-model="groupHost"
                                placeholder="请选择">
                            </el-input>
                        </div>
                        <div class="dialog_group_delet mouse" @click="deleteHost">删除主持人</div>
                    </div>
                    <div class="dialog_group_user" style="margin-top:20px;display:flex;">
                        <div class="dialog_group_user_text" style="margin-top:80px;">
                            <div>
                                选择主持人
                            </div>
                        </div>
                        <div class="dialog_group_user_xz" style="flex:none;">
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex ">
                                        <div class="search_input flex">
                                            <!-- !-- el-input v-model=search placeholder=群组用户el-input --
                                                !-- reserve-keyword -- -->
                                            <el-input @focus="focus" @blur="blur" v-model="InGroupUser.searchName"
                                                placeholder="用户/IMEI">

                                            </el-input>
                                            <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt= />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(1)">
                                            <img src="@/assets/group/search.png" alt= />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <!-- <el-tree ref="host" :data="InGroupUser.userArray" @check="handleNodeClick"
                                            show-checkbox node-key="User_ID">
                                        </el-tree> -->
                                        <el-radio-group v-model="hostId" @change="handleNodeClick">
                                            <el-radio v-for="item in InGroupUser.userArray" :key="item.User_ID"
                                                :label="item.User_ID">
                                                {{ item.label }}
                                            </el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog_group_red" style="margin-top:80px;margin-left:15px;">
                            只能设置一个用户为主持人
                        </div>
                    </div>
                </div>
                <!-- 结束 -->
            </div>
        </div>
        <!-- 编辑结束 -->
        <div class="bottom">
            <el-button class="bc" @click="getSendOut($event)" @keyup.prevent @keydown.enter.prevent>{{ butName }}
            </el-button>
            <el-button class="gb" @click="gbDialog()">{{ cancelBut }}</el-button>
        </div>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import { queryBroadcastRecord } from "@/administration/history.js";
import { updateGroup, queryMember, queryUnMember, removeMember, addMember, updatePriorities, updaetHost, queryGroup } from "@/administration/dispatchLntercom.js";
import EventBus from "@/eventBus/eventBus.js";
import beasconfig from '@/api/beasconfig.js';
import regfunc from "@/method/form/regfunc.js";
import Recorder from 'js-audio-recorder';
let recorder = new Recorder();
import qs from "qs";
import axios from 'axios';
import moment from 'moment';
import { _debounce } from "@/method/utils.js";
export default {
    props: {
        // dialogTableVisible: {       //整个弹出框是否显示
        //     type: Boolean,
        //     default: true
        // },
        openGb: {       //广播显示关闭
            type: Boolean,
            default: false
        },
        dialogTitle: {
            type: String,
            default: ''
        },
        timeShow: {      //选择时间
            type: Boolean,
            default: false
        },
        width: {     //弹出框的宽
            type: String,
            default: '30%'
        },
        bgShow: {    //编辑显示
            type: Boolean,
            default: false
        },
        butName: {       //按钮名称
            type: String,
            default: '发送'
        },
        cancelBut: {       //取消按钮
            type: String,
            default: '取消'
        },
        dialogData: {       //对话框数据
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            userInfo: {

            },
            pickerOptions: {
                disabledDate(time) {
                    let curDate = (new Date()).getTime();
                    let three = 90 * 24 * 3600 * 1000;
                    let threeMonths = curDate - three;
                    return time.getTime() > Date.now() || time.getTime() < threeMonths;
                }
            },
            wideBroadcast: [
                {
                    id: 1,
                    name: '文字广播'
                },
                {
                    id: 3,
                    name: '语音广播'
                }
            ],
            dialogTableVisible: false,
            wideBroadcastId: 1,
            textarea: '',        //文字广播文本输入框
            groupArray: [
                {
                    id: 1,
                    name: '群组编辑'
                },
                {
                    id: 2,
                    name: '用户管理'
                },
                {
                    id: 3,
                    name: '设置主持人'
                }
            ],
            groupId: 1,
            timeData: {
                startTime: '',       //开始时间
                endTime: ''          //结束时间
            },
            groupName: '',        //群组名称
            groupHost: '',   //支持人
            options: [      //用户优先级
                {
                    value: 1,
                    label: '一级'
                }, {
                    value: 2,
                    label: '二级'
                }, {
                    value: 3,
                    label: '三级'
                }, {
                    value: 4,
                    label: '四级'
                }, {
                    value: 5,
                    label: '五级'
                }
            ],
            userValue: '',  //用户优先级
            paging: {
                pageNo: 1,
                pageSize: 10,
                msgType: 1
            },
            broadcastRecordList: [],
            isSendOut: false,     //是否发送
            soundRecordingTime: 0,        //录音时长
            isSoundRecording: false,        //是否在录音
            InGroupUser: {       //在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            },
            noGroupUser: {       //不在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            },
            music: null,
            timeNum: 0,
            temporaryTime: null,
            isPlayLy: false,
            hostId: 0       //主持人id
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        bindInput(value) {        //不能输入
            let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{2,15}$/g;
            let n = value.search(reg);
            if (n === -1 && value.length > 1) {
                this.groupName = value.slice(0, n);
            }
        },
        // 切换
        bindSwitchGb(id, type) {
            if (type == 1) {      //广播显示
                if (this.wideBroadcastId == id) {

                    return
                }
                this.broadcastRecordList = [];
                this.wideBroadcastId = id;
                this.getQueryBroadcastRecord();
            } else if (type == 2) {        //编辑显示
                if (this.groupId == id) {

                    return
                }
                this.InGroupUser.userArray = [];
                this.groupId = id;
                this.InGroupUser.searchName = "";
                this.noGroupUser.searchName = "";
                this.getQueryMember();
                this.getQueryUnMember();
                // if (id == 3) {
                //     this.getQueryGroup();
                // }
            }
        },
        checkOne(data, tree) {       //在组选中
            console.log(data, tree)
            this.InGroupUser.selectUser = tree.checkedNodes;
        },
        checkTow(data, tree) {     //不在组选中
            this.noGroupUser.selectUser = tree.checkedNodes;
        },
        // 打开对话框
        openPg(type, row) {
            this.dialogTableVisible = true;
            // this.getQueryGroup();
            this.refreshValue();
            if (type == 1) {
                this.groupId = 1;
                this.groupName = row.Gname;
                this.getQueryMember();
                this.getQueryUnMember();
            } else if (type == 2) {
                this.getQueryBroadcastRecord();
                // const height = this.$refs.cssTop.clientHeight
                // this.$refs.cssTop.scrollTop = this.$refs.cssTop.scrollHeight;
                // window.scrollTo(0,height)
            }
            // this.groupName = row ? row.Gname : '';
        },
        // 关闭对话框
        gbDialog() {
            this.refreshValue();
            this.dialogTableVisible = false;
        },
        refreshValue() {     //刷新
            this.groupName = '';        //群组名称
            this.groupHost = '';   //支持人
            this.timeData = {
                startTime: '',       //开始时间
                endTime: ''
            }
            this.textarea = '';
            this.InGroupUser = {       //在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            }
            this.noGroupUser = {       //不在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            }
            this.isSendOut = false;     //是否发送
            this.soundRecordingTime = 0;        //录音时长
            this.isSoundRecording = false;        //是否在录音
            this.hostId = 0;
            this.wideBroadcastId = 1;
            this.timeNum = 0;
            this.eliminateTime();
            let i = this.broadcastRecordList.findIndex(item => item.bool == true);
            if (i >= 0) {
                this.music.pause()
                this.broadcastRecordList[i].bool = false;
                this.music = null;
            }
            this.broadcastRecordList = [];
            if (!this.isPlayLy) {
                recorder.pausePlay();
            }
            // this.isPlayLy = false;
        },
        soundRecording(type) {       //录音
            let that = this;
            let i = this.broadcastRecordList.findIndex(item => item.bool == true);
            // if (that.$store.state.isSpeech) {     //有人讲话的时候不能录制

            //     return
            // }
            let show = window.speechSynthesis && window.speechSynthesis.speaking ? window.speechSynthesis.speaking : false;     //判断是否有TTS播报在播放
            if (show || this.$store.state.isSpeech || this.$store.state.isBroadcast || this.$store.state.calltThePolice != null) {     //有人讲话的时候不能播放

                return
            }
            if (i >= 0) {
                that.music.pause()
                that.broadcastRecordList[i].bool = false;
                that.music = null;
                that.eliminateTime();
            }
            if (type == 1) {
                EventBus.$emit("radiobroadcast", 1);
                Recorder.getPermission().then(() => {
                    console.log('给权限了');
                    recorder.start().then(() => {
                        // that.$store.state.isBroadcast = true;
                        // 开始录音
                    }, (error) => {
                        // 出错了
                        // that.$store.state.isBroadcast = false;
                        console.log(`${error.name} : ${error.message}`);
                    });
                    recorder.onprocess = function (duration) {       //录音回调
                        if (duration > 60) {
                            that.soundRecordingTime = Math.round(duration);
                            that.isPlayLy = true;
                            recorder.stop();
                            EventBus.$emit("radiobroadcast", 3);
                        } else {
                            that.isSoundRecording = true;
                        }
                    }
                }, (error) => {
                    console.log(`${error.name} : ${error.message}`);
                });
            } else {
                setTimeout(() => {
                    if (recorder.duration < 0.5) {
                        that.$MessageWarning(`录音时间不能少于一秒！`);
                    }
                    EventBus.$emit("radiobroadcast", 3);
                    // that.$store.state.isBroadcast = false;
                    that.isSoundRecording = false;
                    that.isPlayLy = true;
                    that.soundRecordingTime = Math.round(recorder.duration);
                    recorder.stop();
                }, 500)
            }
        },
        startTime(row) {
            //开始计时
            let that = this;
            let index = that.broadcastRecordList.findIndex(item => item.MSG_UUID == row.MSG_UUID);
            function timerTow() {
                return setInterval(() => {
                    that.timeNum++;
                    if (that.timeNum >= Math.abs(row.MSG / 1000)) {
                        if (index < 0) {
                            that.isPlayLy = true;
                        } else {
                            that.broadcastRecordList[index].bool = false;
                        }
                        that.music = null;
                        that.timeNum = 0;
                        clearInterval(that.temporaryTime);
                    }
                }, 1000);
            }
            that.temporaryTime = timerTow();
        },
        playLy() {       //播放录音
            let show = window.speechSynthesis && window.speechSynthesis.speaking ? window.speechSynthesis.speaking : false;     //判断是否有TTS播报在播放
            if (show || this.$store.state.isSpeech || this.$store.state.isBroadcast || this.$store.state.calltThePolice != null) {     //有人讲话的时候不能播放

                return
            }
            // EventBus.$emit("radiobroadcast",4);
            this.isPlayLy = !this.isPlayLy;
            let i = this.broadcastRecordList.findIndex(item => item.bool == true);
            if (i >= 0) {
                this.music.pause()
                this.broadcastRecordList[i].bool = false;
                this.music = null;
                this.eliminateTime();
            }
            if (!this.isPlayLy) {
                recorder.play();
                this.startTime({ MSG: this.soundRecordingTime * 1000 });
            } else {
                this.eliminateTime();
                recorder.pausePlay();
            }
            // recorder.play(function (res) {
            //     console.log(res)
            // });
        },
        playLyOne(row, index) {       //播放录音
            let show = window.speechSynthesis && window.speechSynthesis.speaking ? window.speechSynthesis.speaking : false;     //判断是否有TTS播报在播放
            if (show || this.$store.state.isSpeech || this.$store.state.isBroadcast || this.$store.state.calltThePolice != null) {     //有人讲话的时候不能播放

                return
            }
            const userState = JSON.parse(sessionStorage.getItem("userState"));
            // console.log(row)
            let i = this.broadcastRecordList.findIndex(item => item.bool == true);
            if (i >= 0 && row.SEND_TIME != this.broadcastRecordList[i].SEND_TIME) {       //如果上一条有播放则直接播放下一条
                this.broadcastRecordList[i].bool = false;
                this.music.pause();
                this.music = null;
                this.eliminateTime();
            }
            if (!this.isPlayLy) {
                recorder.pausePlay();
                this.isPlayLy = true;
                this.eliminateTime();
            }
            if (this.music != null) {
                this.eliminateTime();
                this.music.pause();
                this.music = null;
                this.broadcastRecordList[index].bool = false;
                return
            } else {
                this.startTime(row);
                this.broadcastRecordList[index].bool = true;
                this.music = new Audio(`${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/download?user=${row.fromId}&uuid=${row.MSG_UUID}`);
                this.music.play();
            }
        },
        eliminateTime() {    //清除时间
            if (this.temporaryTime != null) {
                clearInterval(this.temporaryTime);
                this.timeNum = 0;
                this.temporaryTime = null;
            }
        },
        deleteLy() {     //刪除录音
            let that = this;
            if (that.soundRecordingTime > 0) {
                that.isSoundRecording = false;
                that.isPlayLy = true
                recorder.stop();
                EventBus.$emit("radiobroadcast", 3);
                recorder.destroy().then(function () {
                    // recorder = null;
                    that.soundRecordingTime = 0;
                });
                that.eliminateTime();
            }
        },
        getQueryBroadcastRecord: _debounce(function () {
            let that = this;
            let data = {
                uId: that.userInfo.id,
                pageNo: that.paging.pageNo,
                pageSize: that.paging.pageSize,
                msgType: that.wideBroadcastId,
                sendId: that.userInfo.User_ID
            }
            queryBroadcastRecord(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    res.data.data.list.forEach(item => {
                        item.bool = false;
                    })
                    that.broadcastRecordList = res.data.data.list.reverse();
                    setTimeout(() => {
                        that.$nextTick(() => {
                            if (that.$refs.cssTop && that.$refs.cssTop.scrollHeight) {
                                that.$refs.cssTop.scrollTop = that.$refs.cssTop.scrollHeight
                            }
                        })
                    }, 200)
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        }, 500),
        // getQueryBroadcastRecord() {     //获取信息接口
        //     // this.paging
        //     let that = this;
        //     let data = {
        //         uId: that.userInfo.id,
        //         pageNo: that.paging.pageNo,
        //         pageSize: that.paging.pageSize,
        //         msgType: that.wideBroadcastId,
        //         sendId: that.userInfo.User_ID
        //     }
        //     queryBroadcastRecord(data).then(res => {
        //         // console.log(res)
        //         if (res.data.code == 200) {
        //             res.data.data.list.forEach(item => {
        //                 item.bool = false;
        //             })
        //             that.broadcastRecordList = res.data.data.list.reverse();
        //             setTimeout(() => {
        //                 that.$nextTick(() => {
        //                     if (that.$refs.cssTop && that.$refs.cssTop.scrollHeight) {
        //                         that.$refs.cssTop.scrollTop = that.$refs.cssTop.scrollHeight
        //                     }
        //                 })
        //             }, 200)
        //         } else {
        //             that.$MessageWarning(res.data.msg);
        //         }
        //     })
        // },
        getSendOut(event) {       //发送
            // console.log(event.pointerType)
            if (event.pointerType == '') {    //按空格的时候是空

                return
            }
            if (this.openGb) {
                if (this.$store.state.isSpeech) {     //有人讲话的时候不能录制

                    return
                }
                // if (this.$store.state.gbInfoVoice.length > 0 && this.wideBroadcastId == 3) {     //不管收到的是什么广播,文字广播可发,语音广播不可发

                //     return
                // }
                if (this.$store.state.voiceCallInfo.callType != 0 && this.wideBroadcastId == 3) {
                    this.$MessageWarning('正在语音通话！');
                    return
                }
                if (this.$store.state.videoCallInfo.callType != 0 && this.wideBroadcastId == 3) {
                    this.$MessageWarning('正在视频呼叫！');
                    return
                }
                if (this.$store.state.isBroadcast && this.wideBroadcastId == 3) {

                    return
                }
                if (this.textarea.trim() == '' && this.wideBroadcastId == 1) {
                    this.$MessageWarning('请输入文字广播！');
                    return
                }
                if (recorder.duration < 0.5 && this.wideBroadcastId == 3) {
                    this.$MessageWarning('请录制语音广播！');
                    return
                }
                if (this.soundRecordingTime == 0 && this.wideBroadcastId == 3) {
                    this.$MessageWarning('录音时间不能少于一秒！');
                    return
                }
                this.getRadioBroadcast();
            }
            if (this.bgShow) {
                // if (this.groupId == 1) {  //群组编辑
                this.groupEdit();
                // }
            }
            if (this.timeShow) {
                let timeData = this.timeData;
                var tiem1 = moment(timeData.startTime).format("YYYY-MM-DD");
                var tiem2 = moment(timeData.endTime).format("YYYY-MM-DD");
                var value1 = moment(this.timeData.startTime).valueOf();
                var value2 = moment(this.timeData.endTime).valueOf();
                if (timeData.startTime == "" || timeData.startTime == null || timeData.endTime == "" || timeData.endTime == null) {
                    this.$MessageWarning(`请选择开始和结束时间`);
                    return
                }
                if (value1 > value2) {
                    this.$MessageWarning(`结束时间不能早于开始时间`);
                    return
                } else if (tiem1 == tiem2) {
                    var vv1 = moment(this.timeData.startTime).format("YYYY-MM-DD HH:mm:ss");
                    var vv2 = moment(this.timeData.endTime).format("YYYY-MM-DD HH:mm:ss");
                    let dialogData = JSON.stringify(this.dialogData);
                    this.$router.push(`/intercom/trajectory?tiem1=${value1}&tiem2=${value2}&vv1=${vv1}&vv2=${vv2}&uid=${this.dialogData.id}&isOrientation=${dialogData}`);
                    this.dialogTableVisible = false;
                    this.timeData = {
                        startTime: '',
                        endTime: ''
                    }
                } else {
                    this.$MessageWarning(`开始时间和结束时间必须为同一天`);
                }
            }
        },
        getRadioBroadcast: _debounce(function () {
            let that = this;
            let groupArray = JSON.parse(sessionStorage.getItem("groupArray"));
            let array = groupArray.filter(item => item.isRadioBroadcast == true);
            let arr = [];
            array.forEach(item => {
                if (item.children.length > 0) {
                    arr.push(...item.children);
                }
            })
            let m = that.deweight(arr); //只传给在线人数
            m = m.filter(item => item.online == 3);
            if (m.length <= 0) {
                this.$MessageWarning(`请先开启接收广播的群组!`);
                return
            }
            const userState = JSON.parse(sessionStorage.getItem("userState"));
            let parmas = {
                from: that.userInfo.User_ID,
                from_name: that.userInfo.nickname,
                to: m.map(item => item.id).join(),
                to_name: m.map(item => item.label).join(),
                msg_type: that.wideBroadcastId,
                // msg: that.textarea,
                source_flag: 3,
                source: '',
                source_name: '',
                msg_descriptor: that.userInfo.User_CompanyID
            }
            if (that.wideBroadcastId == 1) {      //文字广播
                parmas.msg = that.textarea;
                EventBus.$emit("radiobroadcast", 6, m.map(item => item.id), that.textarea);
                axios({
                    url: `${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/send`,
                    method: 'post',
                    headers: beasconfig.tokenSl,
                    data: qs.stringify(parmas),
                    timeout: 3000
                    // responseType: 'json'
                }).then(res => {
                    console.log(res, res.response)
                    let data = res.data.response.result;
                    // this.
                    if (data.code == 200) {
                        this.$message({
                            message: '发送广播成功！',
                            type: "success",
                            center: true,
                            offset: 450
                        });
                        that.textarea = "";
                        that.getQueryBroadcastRecord();
                    } else {
                        that.$MessageWarning(data.msg);
                    }
                })
            }
            if (that.wideBroadcastId == 3) {      //录音广播
                EventBus.$emit("radiobroadcast", 5, m.map(item => item.id)); //走语音服务
                var wav = new File([recorder.getWAVBlob()], '音频.wav', { type: 'audio/wav', lastModified: Date.now() });
                // parmas.file = wav;
                var form = new FormData(),
                    request = new XMLHttpRequest();
                form.append("file", wav);
                form.append("from", that.userInfo.User_ID);
                form.append("from_name", that.userInfo.nickname);
                form.append("to", m.map(item => item.id).join());
                form.append("to_name", m.map(item => item.label).join());
                form.append("msg_type", that.wideBroadcastId);
                form.append("source_flag", 3);
                form.append("source", '');
                form.append("source_name", '');
                form.append("msg_descriptor", that.userInfo.User_CompanyID);
                form.append("msg", that.soundRecordingTime * 1000);
                request.open("POST", `${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/send`, true);
                request.onload = function (oEvent) {
                    console.log(oEvent);
                    that.soundRecordingTime = 0;
                    that.getQueryBroadcastRecord();
                };
                request.send(form);
                that.isSoundRecording = false;
                recorder.destroy();
                this.$message({
                    message: '发送广播成功！',
                    type: "success",
                    center: true,
                    offset: 450
                });
                return
            }
        }, 500),
        // getRadioBroadcast() {

        // },
        prioritiesbtn(id) {        //
            // console.log(id)
            this.userValue = id;
        },
        changeUserAdministration(e) {       //用户管理修改优先级
            let that = this;
            let dialogData = that.dialogData;
            let data = {
                uId: that.userInfo.id,
                gId: dialogData.Gid,
                uid: that.userValue,
                priorities: e
            }
            updatePriorities(data).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    this.$message({
                        message: '优先级设置成功!',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        groupEdit() {       //群组编辑
            let that = this;
            if (!regfunc.rGroupName.test(that.groupName)) {
                that.$MessageWarning('群组名称由2-15位数字,中文,英文组成');
                return
            }
            let dialogData = that.dialogData;
            let data = {
                uId: that.userInfo.id,
                gId: dialogData.Gid,
                groupName: that.groupName
            }
            updateGroup(data).then(res => {       //修改群组名称
                console.log(res)
                if (res.data.code == 200) {
                    that.dialogTableVisible = false;
                    this.$message({
                        message: '保存成功！',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        changeGroup(type) {      //添加 删除
            console.log(type)
            let that = this;
            let data = {
                uId: that.userInfo.id,
                gId: that.dialogData.Gid
            }
            let arr = [];
            // let uids;
            if (type <= 2) {
                arr = that.InGroupUser.selectUser;
                if (that.InGroupUser.userArray.length <= 0) {
                    that.$MessageWarning('移除失败，在组用户列表为空！');
                    return
                }
                if (type == 2 && arr.length == 0) {
                    that.$MessageWarning('请选择需要移除的用户！');
                    return
                }
                data.uids = type == 1 ? that.InGroupUser.userArray.map(item => item.User_ID).join() : arr.map(item => item.User_ID).join();
                that.getRemoveMember(data);
            } else {
                let arr = that.noGroupUser.selectUser;
                if (that.noGroupUser.userArray.length <= 0) {
                    that.$MessageWarning('添加失败，不在组用户列表为空！');
                    return
                }
                if (type == 3 && arr.length == 0) {
                    that.$MessageWarning('请选择需要添加的用户！');
                    return
                }
                let array = type == 3 ? arr : that.noGroupUser.userArray;
                let members = [];
                array.forEach(item => {
                    members.push({
                        user_id: item.User_ID,
                        id: item.id
                    })
                })
                data.members = members;
                that.getAddMember(data);
            }
        },
        getAddMember(data) {        //添加不在组用户
            let that = this;
            addMember(data).then(res => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '添加成功！',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                    that.noGroupUser.searchName = "";
                    that.InGroupUser.searchName = "";
                    that.InGroupUser.selectUser = [];
                    that.noGroupUser.selectUser = [];
                    that.getQueryMember();
                    that.getQueryUnMember();
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        getRemoveMember(data) {     //移除在组用户
            let that = this;
            removeMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.$message({
                        message: '移除成功！',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                    that.noGroupUser.searchName = "";
                    that.InGroupUser.searchName = "";
                    that.InGroupUser.selectUser = [];
                    that.noGroupUser.selectUser = [];
                    that.getQueryMember();
                    that.getQueryUnMember();
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        bindSearch(type) {      //搜索
            if (type == 1) {      //搜索再组成员
                this.getQueryMember();
            } else if (type == 2) {  //搜索不再组成员
                this.getQueryUnMember();
            }
        },
        bindEmpty(type) {        //清空输入框值
            if (type == 1) {
                this.InGroupUser.searchName = "";
            } else if (type == 2) {
                this.noGroupUser.searchName = "";
            }
        },
        async getQueryMember() {       //搜索再组成员
            let that = this;
            let data = {
                uId: that.userInfo.id,
                gId: that.dialogData.Gid
                // keyword: this.InGroupUser.searchName
            }
            if (that.InGroupUser.searchName != "" && that.InGroupUser.searchName != null) {
                data.keyword = that.InGroupUser.searchName
            }
            await queryMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        // if (that.groupId == 1) {
                        item.label = `${item.nickname}(${item.User_Account})`;
                        // } else {
                        //     item.label = item.nickname;
                        // }
                    })
                    that.InGroupUser.userArray = res.data.data;
                    if (that.groupId == 3) {
                        that.getQueryGroup();
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryUnMember() {     //搜索不再组成员
            let that = this;
            let data = {
                gId: that.dialogData.Gid,
                uId: that.userInfo.id,
                keyword: this.noGroupUser.searchName
            }
            queryUnMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.noGroupUser.userArray = res.data.data;
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryGroup() {       //查询群组详情
            let that = this;
            queryGroup({
                id: that.dialogData.Gid
            }).then(res => {
                let data = res.data.data;
                if (res.data.code == 200) {
                    // console.log(res)
                    that.hostId = data.Cg_Dispatcher;
                    let InGroupUser = that.InGroupUser.userArray.find(item => item.User_ID == data.Cg_Dispatcher);
                    if (InGroupUser) {
                        //     let array = [];
                        that.groupHost = InGroupUser.nickname;
                        //     // this.$refs.host.setCheckedKeys([InGroupUser.User_ID]);
                        //     that.InGroupUser.userArray.forEach(item => {
                        //         if (InGroupUser.User_ID != item.User_ID) {
                        //             item.disabled = true;
                        //         }
                        //         array.push(item)
                        //     })
                        //     that.InGroupUser.userArray = array;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        handleNodeClick(id) {        //主持人选中
            console.log(id)
            let data = {
                uId: this.userInfo.id,
                gId: this.dialogData.Gid,
                hostId: id
            }
            updaetHost(data).then(res => {
                if (res.data.code == 200) {
                    this.getQueryGroup();
                    this.$message({
                        message: '设置主持人成功！',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
            // else {
            //     this.$message({
            //         message: '只能设置一个用户为主持人',
            //         type: "warning",
            //         center: true,
            //         offset: 450
            //     });
            // }
        },
        deleteHost() {       //删除主持人
            let data = {
                uId: this.userInfo.id,
                gId: this.dialogData.Gid,
                hostId: 0
            }
            updaetHost(data).then(res => {
                if (res.data.code == 200) {
                    this.groupHost = "";
                    this.hostId = 0;
                    this.getQueryGroup();
                    let array = [];
                    this.InGroupUser.userArray.forEach(item => {
                        item.disabled = false;
                        array.push(item)
                    })
                    this.InGroupUser.userArray = array;
                    this.$message({
                        message: '删除成功！',
                        type: "success",
                        center: true,
                        offset: 450
                    });
                }
            })
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr;
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        },
        gbTimeOne(data) {        //计算所需要的宽度
            let f = this.$refs.timeMsg.offsetWidth - 105 - 150;
            let m = Math.round(data / 1000);
            let wid = `width: ${f}px;`;
            if (m <= 60) {
                let width = Math.round(f / 60) * m;
                wid = `width: ${85 + width}px;`;
            }
            return wid;
        },
        mute() {     //静音
            this.eliminateTime();
            let i = this.broadcastRecordList.findIndex(item => item.bool == true);
            if (i >= 0) {
                this.music.pause()
                this.broadcastRecordList[i].bool = false;
                this.music = null;
            }
            if (!this.isPlayLy) {
                this.isPlayLy = true;
                recorder.pausePlay();
            }
            if (this.isSoundRecording) {
                this.isSoundRecording = false;
                this.isPlayLy = true;
                this.soundRecordingTime = Math.round(recorder.duration);
                recorder.stop();
                EventBus.$emit("radiobroadcast", 3);
                // this.$store.state.isBroadcast = false;
            }
        }
    },
    mounted() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (this.bgShow) {
            this.groupName = this.dialogData.Gname;
            this.getQueryMember();
            this.getQueryUnMember();
        }
        if (this.openGb) {
            this.getQueryBroadcastRecord();
        }
    },
    filters: {
        rounding: function (duration) { //录音取整
            return Math.round(duration)
        },
        gbTime: function (data) {

            return Math.round(data / 1000);
        }
    },
    watch: {
        // dialogData: {
        //     handler(row) {
        //         console.log(row)
        //         this.groupName = row ? row.Gname : '';
        //     },
        //     deep: true,
        //     immediate: true
        // }
        "$store.state.sosPushInfo"(newVal) {
            //对数据执行操作
            if (newVal.length > 0) {
                this.mute();
            }
        },
        "$store.state.isBroadcast"(bool) {
            //是否有语音播放
            //对数据执行操作
            if (bool == true) {
                this.mute();
            }
        },
        "$store.state.isTextBb"(bool) {
            //是否有语音播放
            //对数据执行操作
            if (bool == true) {
                this.mute();
            }
        },
        '$store.state.isSpeech'(newVal) {
            //对数据执行操作
            if (newVal) {
                this.mute();
            }
        },
        "$store.state.gbInfoText"(newVal) {        //文字广播
            //对数据执行操作
            if (newVal.length > 0) {
                this.mute();
            }
        },
        "$store.state.calltThePolice"(newVal) {        //围栏报警
            //对数据执行操作
            if (newVal != null) {
                this.mute();
            }
        },
        "$store.state.videoCallInfo.callType"(type) {       //正在通话中~
            if (type != 0) {
                this.mute();
            }
        },
        "$store.state.voiceCallInfo.callType"(type) {       //正在通话中~
            if (type != 0) {
                this.mute();
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 6px;
    background-color: #076e6e;
}

::-webkit-scrollbar-thumb {
    background-color: #00feff;
}

/deep/.el-dialog {
    background: #0F3343;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }

    .dialog {
        width: 94%;
        background-color: #08222E;
        margin: auto;
        color: #fff;
        border: 1px solid #07415A;

        &_block {
            margin-bottom: 15px;

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            .el-icon-date:before {
                display: none;
            }

            .el-input__inner {
                width: 195px;
                height: 35px;
                background-color: #08222E;
                border: 1px solid #327676;
                color: #fff;
            }
        }

        &_text {
            font-size: 14px;
        }

        &_left {
            width: 120px;
            height: 100%;
            background-color: #0a2a38;
            border-right: 1px solid #07415A;

            &_groupBg {
                width: 100%;
                height: 75px;
                line-height: 75px;
                text-align: center;
            }

            &_groupBg:first-child {
                border-top: none !important;
            }

            &_bg {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #08222E;
                border-top: 1px solid #07415A;
                border-bottom: 1px solid #07415A;
                border-right: 1px solid #08222E;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            margin: auto;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #07415A;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 6px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        // width: 80%;
                        min-height: 40px;
                        background-color: #2e7777;
                        border-radius: 5px;
                        font-size: 14px;

                        &_text {
                            padding: 5px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        min-width: 50px;
                        color: #727171;
                        margin-top: 20px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4AFFFF;
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 92%;
            margin: auto;

            &_top {
                width: 100%;
                height: 75px;
                border-bottom: 1px solid #07415A;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: rgba(77, 77, 77, 0);
                    border: 1px solid #07415A;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                font-size: 12px;
                color: red;
            }

            &_input {
                width: 285px;
                height: 40px;
                margin: 0px 10px;
            }

            &_user {
                width: 100%;
                height: 370px;

                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    flex: 1;
                    display: flex;

                    &_box {
                        width: 280px;
                        height: 370px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 25px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #6e6d6d;
                    overflow: hidden;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                            font-size: 14px !important;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background: rgba(50, 118, 118, 0.5);
                border: 1px solid #327676;
                color: #3CE6E6;
                margin-left: 10px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: #4AFFFF;
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

/deep/.el-input.is-disabled .el-input__inner {
    background: transparent;
}

/deep/.el-input__icon {
    display: none;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #08222E;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search_tow {
    width: 415px;
    height: 45px;
    border: 1px solid #2e7777;
    background-color: #08222E !important;
    border-radius: 2px;

    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 45px !important;
        line-height: 45px !important;
        background-color: #08222E !important;
        color: #ffffff;
        border: none !important;
        border-radius: 0px;
        padding: 0px 10px;
    }

    &_input {
        flex: 1;
        height: 100%;
        background-color: #08222E;
        color: #ffffff !important;

        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 55px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: #08222E;
    border: 1px solid #327676;

    &_input {
        flex: 1;
        height: 100%;
        background-color: #08222E;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #4AFFFF !important;
        background-color: transparent !important;
        color: #4AFFFF !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: #4affff;
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: #4affff;
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        line-height: 28px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

/deep/.el-radio-group {
    display: flex;
    flex-direction: column;

    .el-radio {
        color: #ffffff;
        margin: 0px 10px 5px 10px;
        display: flex;
        align-items: center;

        &>.el-radio__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px 0px 5px 10px;
        }
    }

    .el-radio__inner {
        background-color: #4D4D4D;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ffffff;
        background: #4D4D4D;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #4AFFFF;
    }

    .el-radio__inner:hover {
        border-color: #4AFFFF;
    }

    .el-radio__inner::after {
        width: 8px;
        height: 8px;
        background-color: #4affff;
    }
}



.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>