<template>
    <div :class="['box prohibitCopy', !isZk ? 'height_auto' : '', $route.meta.navId == 1 ? 'box_bg' : '']">
        <!-- 230  170 -->
        <template v-if="$route.meta.navId <= 2">
            <img :class="['open_img', openImgStyle()]" v-if="isZk" @click="isZk = false" src="../assets/open3.png"
                alt="">
            <img class="open_img open_img_rotate" v-if="!isZk" @click="isZk = true" src="../assets/open3.png" alt="">
        </template>
        <div :class="['bottom', bottomStyle()]" v-if="$store.state.userSpeech.isShow || $store.state.userSpeech.isBool" >
            <div class="bottom_popup">
                <div class="flex_align" v-if="$store.state.userSpeech.groupname">
                    <img v-if="$route.meta.navId == 1" src="@/assets/callkey/group.png" alt="">
                    {{ $store.state.userSpeech.groupname }}
                </div>
                <div class="flex_grow bottom_popup_text flex_align">
                    <img v-if="$route.meta.navId == 1" src="@/assets/callkey/user.png" alt="">
                    {{ $store.state.userSpeech.isShow ? $store.state.userSpeech.title : $store.state.userSpeech.userTitle }}
                </div>
            </div>
        </div>
        <div :class="['call', callStyle(), $store.state.userState.isTalkButton == 1 && $route.meta.navId == 2 ? 'text_align_left' : '']"
            v-if="isZk && $route.meta.navId <= 2 || $route.meta.navId > 2">
            <img class="callimg" v-if="$route.meta.navId == 1" @click="call(1)" src="@/assets/callkey/th.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 2" @click="call(2)" src="@/assets/callkey/addwl.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 3" @click="call(1)" src="@/assets/callkey/video.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 1 || $route.meta.navId == 2" @click="call(2)"
                src="@/assets/callkey/call.png" alt="">
            <img class="callptt" v-if="$store.state.userState.isTalkButton == 1" @mousedown="PTT(1)" @mouseup="PTT(2)"
                @mouseleave="PTT(2)" src="@/assets/callkey/ptt.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 1" @click="call(3)" src="@/assets/callkey/ql.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 1" @click="call(4)" src="@/assets/callkey/qc.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 2" @click="call(3)" src="@/assets/callkey/yj.png" alt="">
            <img class="callimg" v-if="$route.meta.navId == 3" @click="call(2)" src="@/assets/callkey/video1.png"
                alt="">
            <!-- <img v-for="item in callArr" :key="item.id"  :src="item.url" alt=""
                :class="item.id === 0 ? 'callptt' : 'callimg'"> -->
        </div>
    </div>
</template>

<script>
import EventBus from "@/eventBus/eventBus.js";
export default {
    components: {},
    data() {
        return {
            callArr: [
                //调度对讲群组底部方法
                {
                    id: 1,
                    name: "通话",
                    url: require("@/assets/callkey/th.png"),
                },
                {
                    id: 2,
                    name: "呼叫",
                    url: require("@/assets/callkey/call.png")
                },
                {
                    id: 0,
                    name: "PTT",
                    url: require("@/assets/callkey/ptt.png")
                },
                {
                    id: 3,
                    name: "强拉",
                    url: require("@/assets/callkey/ql.png")
                },
                {
                    id: 4,
                    name: "强拆",
                    url: require("@/assets/callkey/qc.png")
                }
            ],
            sPGroupArray: [
                //实时视频群组底部方法
                {
                    id: 1,
                    name: "视频呼叫",
                    url: require("@/assets/group/group7.png"),
                },
                {
                    id: 2,
                    name: "强拉视频",
                    url: require("@/assets/group/group6.png"),
                }
            ],
            dzGroupArray: [
                //电子围栏群组底部方法
                {
                    id: 1,
                    name: "呼叫",
                    url: require("@/assets/group/group2.png"),
                },
                {
                    id: 2,
                    name: "创建围栏",
                    url: require("@/assets/group/group5.png"),
                },
                {
                    id: 3,
                    name: "越界记录",
                    url: require("@/assets/group/group8.png"),
                }
            ],
            isZk: true,
            visible: true
        };
    },
    props: {

    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    },
    filters: {

    },
    methods: {
        bottomStyle() {
            let navId = this.$route.meta.navId;
            if (!this.isZk && navId <= 2) {
                navId = 0;
            }
            let style = {
                0: 'bottom_top',
                1: '',
                2: 'bottom_weilan',
                3: 'bottom_video',
                4: 'bottom_jl'
            };
            return style[navId];
        },
        openImgStyle() {
            let navId = this.$route.meta.navId;
            let style = {
                1: '',
                2: 'open_img_weilan'
            };
            return style[navId];
        },
        callStyle() {
            let navId = this.$route.meta.navId;
            let style = {
                1: '',
                2: 'call_top',
                3: 'call_top',
                4: 'call_jl'
            };
            return style[navId];
        },
        call(id) { //呼叫
            let find = {};
            if (this.$route.meta.navId == 1) {
                find = this.callArr.find(item => item.id == id);
            } else if (this.$route.meta.navId == 2) {
                find = this.dzGroupArray.find(item => item.id == id);
            } else if (this.$route.meta.navId == 3) {
                find = this.sPGroupArray.find(item => item.id == id);
            }
            EventBus.$emit("groupMethod", find);
        },
        PTT(type) {
            //讲话按键
            if (this.$store.state.isBroadcast) {
                return;
            }
            if (this.$store.state.pttIntercom && !this.visible) {  //判断点击ptt的时候是否在按空格

                return;
            }
            if (type == 1) {
                if (this.$store.state.voiceCallInfo.callType != 0) {
                    this.$MessageWarning('正在语音通话！');
                    return
                }
                if (this.$store.state.videoCallInfo.callType != 0) {
                    this.$MessageWarning('正在视频呼叫！');
                    return
                }
                this.$store.state.pttIntercom = true;
                EventBus.$emit("pptJh", 1);
                this.visible = true;
            } else {
                EventBus.$emit("pptJh", 2);
                this.visible = false;
                this.$store.state.pttIntercom = false;
            }
        },
    },
    watch: {

    }
};
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
}

.box_bg {
    height: 100px;
    background: url(../assets/open2.png);
    background-size: 100% 150%;
    transition: height 0.5s;
}

.height_auto {
    height: 0px !important;
    transition: height 0.5s;
}

.callbox {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
}

.open_img {
    width: 234px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -70px;
    z-index: 15;
}

.open_img_rotate {
    transform: rotate(180deg);
    top: -40px !important;
}

.open_img_weilan {
    top: -170px;
}

.call {
    width: 575px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -20px;
    z-index: 15;
    text-align: center;
}

.text_align_left {
    text-align: left;
}

.call_top {
    top: -120px;
}

.call_jl {
    top: -178px;
}

.call>img {
    margin-right: 40px;
}

.call>img:last-child {
    margin: 0px;
}

.callimg {
    width: 77px;
    height: 77px;
}

.callptt {
    width: 105px;
    height: 106px;
}

.bottom {
    // width: 250px;
    position: absolute;
    top: -150px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &_popup {
        // width: 230px;
        // height: 96px;
        // line-height: 48px;
        display: inline-block;
        padding: 10px 10px;
        text-align: center;
        background-color: #01121B;
        opacity: 0.9;
        border-radius: 10px;
        border: 1px solid #01FBFD;
        font-size: 14px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &>div {
            height: 20px;
            margin-bottom: 10px;
        }

        &>div:last-child {
            margin: 0px;
        }

        img {
            width: 20px;
            margin-right: 10px;
        }

        &_text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.bottom_top {
    top: -120px;
}

.bottom_weilan {
    top: -240px;
}

.bottom_video {
    top: -200px;
}

.bottom_jl {
    top: -248px;
}
</style>