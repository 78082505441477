<template>
  <div class="box" id="wrapp">
    <img class="tz mouse" @click="notice" src="../assets/tz.png" alt="" />
    <div
      class="sos"
      v-if="$store.state.sosPushInfo.length > 0"
      style="z-index: 17"
    >
      <div class="sos_head flex_align">
        <img
          src="@/assets/sos/sos_hb.png"
          style="width: 74px; height: 74px"
          alt=""
        />
        <div class="sos_Circular">
          {{ $store.state.sosPushInfo.length | ninetyNine }}
        </div>
        <img
          class="sos_gb"
          @click="closeSos(1)"
          src="@/assets/sos/sos_gb.png"
          alt=""
        />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl mag_bottom">
          <span>名称：</span
          >{{
            $store.state.sosPushInfo[$store.state.sosIndex].sosContent
              .sosSendName
          }}
        </div>
        <div class="sos_content_address mag_bottom">
          <span>地址：</span
          >{{
            $store.state.sosPushInfo[$store.state.sosIndex].sosContent
              .sosLocationAt.address
          }}
        </div>
        <div class="sos_content_sl">
          <span>时间：</span
          >{{
            $store.state.sosPushInfo[$store.state.sosIndex].sosContent.sosStamp
              | timeTransformation
          }}
        </div>
      </div>
      <div class="sos_bottom flex_align mag_bottom">
        <div
          class="sos_but"
          @click="seeOssPosition"
          style="background-color: red"
        >
          查看
        </div>
        <div
          class="sos_but"
          v-if="$store.state.sosPushInfo.length > 1"
          @click="next"
          style="
            margin-left: 25px;
            background-color: transparent;
            border: 2px solid #aaaaaa;
            height: 41px;
            line-height: 41px;
            color: #aaaaaa;
          "
        >
          下一条
        </div>
      </div>
    </div>
    <div
      class="sos"
      style="width: 335px"
      v-if="$store.state.enclosureTc.length > 0"
    >
      <!-- 越界 -->
      <!-- && $store.state.gbInfoVoice[0].msg_type == 3 -->
      <div class="sos_head flex_align">
        <img
          src="@/assets/sos/sos_yj.png"
          style="width: 74px; height: 74px"
          alt=""
        />
        <img
          class="sos_gb mouse"
          @click="closeSos(2)"
          style="top: 25px"
          src="@/assets/sos/sos_gb.png"
          alt=""
        />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl mag_bottom">
          <span>越界人：</span>
          {{ $store.state.enclosureTc[0].Info.userName }}
        </div>
        <div class="sos_content_address mag_bottom">
          <span>围栏：</span>{{ $store.state.enclosureTc[0].Info.WLName }}
        </div>
        <div class="sos_content_sl">
          <span>时间：</span
          >{{ $store.state.enclosureTc[0].Info.Time | timeTransformationTow }}
        </div>
      </div>
    </div>
    <!-- 广播 -->
    <div
      class="sos"
      style="width: 360px"
      v-if="$store.state.gbInfoVoice.length > 0"
    >
      <div class="sos_head flex_align">
        <img
          src="@/assets/sos/gb.png"
          style="width: 74px; height: 74px"
          alt=""
        />
        <img
          class="sos_gb mouse"
          @click="closeSos(3)"
          src="@/assets/sos/sos_gb.png"
          alt=""
        />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl mag_bottom">
          <span>广播人：</span>{{ $store.state.gbInfoVoice[0].from_name }}
        </div>
        <div class="sos_content_sl">
          <span>广播时间：</span
          >{{ $store.state.gbInfoVoice[0].send_time | timeTransformation }}
        </div>
      </div>
      <div class="sos_bottom flex_align mag_bottom">
        <div
          class="sos_but mouse"
          @click="listenInGb"
          style="background-color: #ffb400; color: #333333"
        >
          收听
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div
      class="sos"
      style="width: 360px"
      v-if="
        $store.state.videoCallInfo.echoList.length > 0 &&
        $store.state.videoCallInfo.switchVideo == 0
      "
    >
      <div class="sos_head flex_align">
        <img
          src="@/assets/sos/sphc.png"
          style="width: 74px; height: 74px"
          alt=""
        />
        <img
          class="sos_gb mouse"
          @click="seeEchoList(1, 'video')"
          src="@/assets/sos/sos_gb.png"
          alt=""
        />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl mag_bottom">
          <span>回传人：</span
          >{{
            $store.state.videoCallInfo.echoList[
              $store.state.videoCallInfo.echoList.length - 1
            ].name
          }}
        </div>
        <div class="sos_content_sl">
          <span>回传时间：</span
          >{{
            $store.state.videoCallInfo.echoList[
              $store.state.videoCallInfo.echoList.length - 1
            ].inviteTime | timeTransformation
          }}
        </div>
      </div>
      <div class="sos_bottom flex_align mag_bottom">
        <div
          class="sos_but mouse"
          @click="seeEchoList(0, 'video')"
          style="background-color: #4affff; color: #333333"
        >
          查看
        </div>
      </div>
    </div>

    <!-- 视频图片上传 -->
    <div
      class="sos"
      style="width: 360px"
      v-if="$store.state.uploadArr.length > 0"
    >
      <div class="sos_head flex_align">
        <img
          src="@/assets/sos/wjsc.png"
          style="width: 74px; height: 74px"
          alt=""
        />
        <img
          class="sos_gb mouse"
          @click="seeEchoList(1, 'upload')"
          src="@/assets/sos/sos_gb.png"
          alt=""
        />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl mag_bottom">
          <span>名称：</span
          >{{
            $store.state.uploadArr[$store.state.uploadArr.length - 1].sendname
          }}
        </div>
        <div class="sos_content_sl mag_bottom">
          <span>上传类型：</span
          >{{
            $store.state.uploadArr[$store.state.uploadArr.length - 1].msgtype ==
            2
              ? "图片上传"
              : "视频上传"
          }}
        </div>
        <div class="sos_content_sl">
          <span>时间：</span
          >{{
            $store.state.uploadArr[$store.state.uploadArr.length - 1].date
              | timeTransformation
          }}
        </div>
      </div>
      <div class="sos_bottom flex_align mag_bottom">
        <div
          class="sos_but mouse"
          @click="seeEchoList(0, 'upload')"
          style="background-color: #4affff; color: #333333"
        >
          查看
        </div>
      </div>
    </div>
    <div class="giveAnAlarm" v-if="isOpen">
      <div class="giveAnAlarm_title">告警信息</div>
      <div class="giveAnAlarm_gb mouse" @click="isOpen = false">×</div>
      <div class="giveAnAlarm_info">
        <div
          v-for="(item, index) in warningInfo"
          :key="index"
          class="flex_align"
        >
          <div class="giveAnAlarm_info_one ellipsis_app" style="color: #4affff">
            {{ item.User_Name }}
          </div>
          <div style="margin: 0px 10px">
            {{ item.date | timeTransformationOne }}
          </div>
          <div class="ellipsis_app" style="flex: 1">
            <el-tooltip
              style="width: 100%"
              :content="item | infoNotice"
              placement="left"
            >
              <span>{{ item | infoNotice }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="giveAnAlarm_tips">更多告警消息请查看历史记录</div>
    </div>
    <!-- <audio controls autoplay src='https://apidev.xin-ptt.com:9001/mms-ptt01/api/v1/media/download?user=3897&uuid=8f877ac6a5c345a3a5c0d8e8087deb43'></audio> -->
  </div>
</template>

<script></script>
<script>
import {
  getNearly7DaysOnline,
  getRecordFirstB,
} from "../administration/dispatchLntercom.js";
// import { EleResize } from "../administration/esresize.js";
import EventBus from "@/eventBus/eventBus.js";
import sosWav from "@/method/wav/sos_received.wav";
import fence from "@/method/wav/fence_receiced.wav";
import { _throttle } from "@/method/utils.js";
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
import beasconfig from "@/api/beasconfig.js";
// const userState = JSON.parse(sessionStorage.getItem("userState"));
import textGb2x from "../assets/sos/textGb2x.png";
import textGb2x1 from "../assets/sos/textGb12x.png";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      sevenDaysData: [], //七天数据
      userInfo: {}, //用户数据
      echartsData: {
        //echarts数据
        width: "305px",
        height: "190px",
      },
      statistics: {
        //統計
        onlineUserTotal: [0, 0, 0, 0, 0], //在线用户总数
        userTotal: 0, //用户总数
        fixedGroup: 0, //固定群组数量
        enclosureCount: 0, //电子围栏记录数
        sosCount: 0, //sos记录数
      },
      visible: true,
      timeNum: 0,
      temporaryTime: null,
      isOpen: false,
      warningInfo: [], //告警信息
      giveanalarmType: 0,
      isPlay: false, //是否播放
    };
  },
  props: {
    userState: {
      // type: Object,
      default: {
        isBindRoad: 0, // 是否绑路 0 否 1是
        sosVoice: 0, //sos声音开关
        speechRecognition: 0, //语音识别
        fullDuplex: 0, //全双工
        fullDuplexAudioTime: 0,
        isTalkButton: 0, //对讲按键显示
        enclosureVoice: 0, //围栏声音
      },
    },
  },
  created() {
    // console.log(JSON.parse(sessionStorage.getItem("groupArray")))
    let that = this;
    // that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    that.getNearly7DaysOnline();
    EventBus.$on("controlGb", function (type) {
      //控制广播
      if (type == 1) {
        that.handleSpeak();
      } else {
        that.handleStop();
      }
    });
    //  '@/assets/sos/textGb2x.png'
  },
  mounted() {
    window.closeMaker = this.closeMaker;
  },
  beforeDestroy() {
    this.suspendPlay();
    if (
      (this.$store.state.gbInfoText.length > 0 &&
        this.$store.state.calltThePolice != null) ||
      this.$store.state.gbInfoVoice.length > 0
    ) {
      this.suspendPlay1();
      this.handleStop();
    }
    this.$store.state.gbInfoVoice = [];
    this.$store.state.gbInfoText = [];
    this.$store.state.sosPushInfo = [];
    this.$store.state.sosCallthepolice = false;
    this.$store.state.enclosureTc = [];
    this.$message.closeAll(); //关闭弹出框提示
    EventBus.$off("controlGb");
  },
  filters: {
    infoNotice: function (item) {
      //消息通知
      let title;
      let type = item.recordType;
      if (type == 1) {
        //1sos
        title = `发起sos`;
      } else if (type == 2) {
        //电子围栏
        title = `${item.efType == 0 ? "离开" : "进入"}${item.EF_Name}围栏`;
      } else if (type == 3) {
        //视频
        let text =
          item.type == 101
            ? "强拉视频"
            : item.type == 200
            ? "视频呼叫"
            : "视频回传";
        title = text;
      } else if (type == 4) {
        //上传
        title = item.MSG_TYPE == 2 ? "图片上传" : "视频上传";
      }
      return title;
    },
    groupNum: function (data) {
      return data.filter((item) => item.online == 3).length;
    },
    ninetyNine: function (value) {
      if (value > 99) {
        value = 99;
      }
      return value;
    },
    timeTransformationOne: function (value) {
      let time = new Date(); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      let time1 = moment(time).format("YYYY-MM-DD");
      let time2 = moment(value).format("YYYY-MM-DD");
      return time1 > time2
        ? moment(value).format("YYYY-MM-DD")
        : moment(value).format("HH:mm:ss"); //大于今天的话显示年月日 否则显示时分秒
    },
    timeTransformationTow: function (value) {
      let time = new Date(value); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      time.setHours(time.getHours() + 8);
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    timeTransformation: function (value) {
      let time = new Date(value); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      let year = time.getFullYear(); //获取年 2021
      let month = time.getMonth() + 1; //获取月  5
      let day = time.getDate(); //获取天  11
      let h = time.getHours(); //获取小时  18
      let m = time.getMinutes(); //获取分钟  42
      let s = time.getSeconds(); //获取秒  51
      if (h >= 0 && h <= 9) {
        h = "0" + h;
      }
      if (m >= 0 && m <= 9) {
        m = "0" + m;
      }
      if (s >= 0 && s <= 9) {
        s = "0" + s;
      }
      return year + "年" + month + "月" + day + "日" + h + ":" + m + ":" + s;
    },
  },
  methods: {
    notice() {
      //查看最近8条警告记录
      this.isOpen = !this.isOpen;
      let data = {
        uid: this.$store.state.userInfo.User_ID,
        oId: this.$store.state.userInfo.User_CompanyID,
      };
      getRecordFirstB(data).then((res) => {
        if (res.data.code == 200) {
          this.warningInfo = res.data.data;
        } else {
          this.$MessageSuccess(res.data.msg);
        }
      });
    },
    seeOssPosition() {
      //查看oss
      let row = this.$store.state.sosPushInfo[this.$store.state.sosIndex];
      this.ossMap = {
        sos_location_at: row.sosContent.sosLocationAt,
        sendname: row.sosContent.sosSendName,
        time: this.$moment(row.sosContent.sosStamp).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        cg_name: row.sosContent.cgName,
        sos_msg_id: row.sosContent.sosMsgId,
        sosStatus: row.sosContent.sosStatus,
      };
      this.$store.state.sosCallthepolice = false;
      this.$store.state.sosPushInfo.splice(this.$store.state.sosIndex, 1);
      this.$store.state.calltThePoliceType = 1;
      if (this.$store.state.enclosureTc.length > 0) {
        this.giveanalarmType = 2;
      }
      this.suspendPlay();
      this.$router.push(
        `/history/sosPosition?ossMap=${JSON.stringify(this.ossMap)}`
      );
    },
    seeEchoList(type, typeCk) {
      //查看视频
      if (typeCk == "upload") {
        if (type == 0) {
          this.$router.push("/history/uploadRecord");
        }
        this.$store.state.uploadArr = [];
      } else {
        if (type == 0) {
          this.$router.push("/promotion/videoInfo");
          setTimeout(() => {
            EventBus.$emit("switchVideo", 1);
          }, 500);
        }
        this.$store.state.videoCallInfo.echoList = [];
      }
    },
    listenInGb() {
      //收听广播
      //  if(this.$store.state.isBroadcast){
      //     return
      //  }
      this.handleStop();
      this.suspendPlay();
      this.$store.state.isBroadcast = true;
      this.$store.state.calltThePolice = new Audio(
        `${beasconfig.mmsUrlapi}-${this.userState.server}/api/v1/media/download?user=${this.$store.state.gbInfoVoice[0].from}&uuid=${this.$store.state.gbInfoVoice[0].uuid}`
      );
      this.$store.state.calltThePolice.pause();
      setTimeout(() => {
        this.$store.state.calltThePolice.play();
        this.$store.state.gbInfoVoice.splice(0, 1);
        this.$store.state.calltThePolice.addEventListener("ended", () => {
          //语音播放结束
          this.$store.state.isBroadcast = false;
          this.$store.state.calltThePolice = null;
          if (this.$store.state.sosPushInfo > 0) {
            this.giveanalarmType = 1; //播放sos
            this.playWav();
          }
        });
      }, 500);
      this.hangup();
    },
    hangup() {
      //挂断视频
      if (
        this.$route.name == "videoInfo" &&
        this.$store.state.videoCallInfo.callType > 0
      ) {
        EventBus.$emit("gdVideoCall", 0);
      }
      if (this.$store.state.voiceCallInfo.callType > 0) {
        if (this.$store.state.voiceCallInfo.isAnswerVoice) {
          EventBus.$emit("sendRespond", 0, 0, 1);
        } else {
          EventBus.$emit("sendRespond", 0, 0);
          EventBus.$emit("closeConversation");
        }
      }
    },
    next() {
      //下一条
      // this.suspendPlay();
      this.$store.state.calltThePoliceType = 0;
      // this.$store.state.sosCallthepolice = false;
      this.$store.state.sosPushInfo.splice(this.$store.state.sosIndex, 1);
    },
    PTT(type) {
      //讲话按键
      if (this.$store.state.isBroadcast) {
        return;
      }
      if (this.$store.state.pttIntercom && !this.visible) {
        //判断点击ptt的时候是否在按空格

        return;
      }
      if (type == 1) {
        if (this.$store.state.voiceCallInfo.callType != 0) {
          this.$MessageWarning("正在语音通话！");
          return;
        }
        if (this.$store.state.videoCallInfo.callType != 0) {
          this.$MessageWarning("正在视频呼叫！");
          return;
        }
        this.$store.state.pttIntercom = true;
        EventBus.$emit("pptJh", 1);
        this.visible = true;
      } else {
        EventBus.$emit("pptJh", 2);
        this.visible = false;
        this.$store.state.pttIntercom = false;
      }
    },
    getNearly7DaysOnline() {
      //七天用户数量对比
      let that = this;
      let data = {
        uId: this.$store.state.userInfo.id,
      };
      getNearly7DaysOnline(data).then((res) => {
        var array = res.data.data.map((item) => item.count); //遍历出数值
        let max = Math.max(...array);
        res.data.data.forEach((item) => {
          item.days = that.$moment(item.date).format("MM-DD");
          // that.days(index)
          item.percentage = max > 0 ? Math.abs((item.count / max) * 100) : 0;
        });
        that.sevenDaysData = res.data.data.reverse();
      });
    },
    days(d) {
      //天数
      let days;
      switch (d) {
        case 0:
          days = "第一天";
          break;
        case 1:
          days = "第二天";
          break;
        case 2:
          days = "第三天";
          break;
        case 3:
          days = "第四天";
          break;
        case 4:
          days = "第五天";
          break;
        case 5:
          days = "第六天";
          break;
        case 6:
          days = "第七天";
          break;
      }
      return days;
    },
    closeSos(type) {
      //关闭sos
      if (type == 1) {
        if (this.$store.state.enclosureTc.length > 0) {
          this.giveanalarmType = 2;
        }
        this.$store.state.sosPushInfo = [];
        this.suspendPlay();
        this.$store.state.sosCallthepolice = false;
      } else if (type == 2) {
        //关闭围栏
        this.$store.state.enclosureTc = [];
        this.suspendPlay();
      } else {
        //关闭广播
        // this.$store.state.gbInfoVoice = [];
        this.$store.state.gbInfoVoice.splice(0, 1);
        this.$store.state.isBroadcast = false;
        this.suspendPlay();
      }
    },
    xuanze(arr) {
      //排序
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].num < arr[j].num) {
            var temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
          }
        }
      }
      return arr;
    },
    suspendPlay() {
      //sos暂停播放
      if (this.$store.state.calltThePolice != null) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        clearInterval(this.$store.state.calltThePoliceTimer);
      }
    },
    playWav: _throttle(function () {
      //sos播放语音
      if (
        this.$store.state.calltThePolice == null &&
        this.$store.state.calltThePoliceType == 0
      ) {
        if (this.giveanalarmType == 1) {
          if (this.$store.state.voiceCallInfo.callType != 0) {
            return;
          }
          if (this.$store.state.videoCallInfo.callType != 0) {
            return;
          }
          this.$store.state.calltThePoliceTimer = setInterval(() => {
            if (this.$store.state.sosPushInfo.length > 0) {
              this.$store.state.calltThePolice = new Audio(sosWav);
              this.$store.state.calltThePolice.play();
            }
          }, 500);
        } else if (this.giveanalarmType == 2) {
          if (
            !this.$store.state.isSpeech &&
            this.userState.enclosureVoice == 1 &&
            this.$store.state.gbInfoVoice.length <= 0 &&
            this.$store.state.isBroadcast == false &&
            this.$store.state.videoCallInfo.callType == 0 &&
            this.$store.state.voiceCallInfo.callType == 0
          ) {
            this.$store.state.calltThePolice = new Audio(fence);
            this.$store.state.calltThePolice.play();
          }
          this.startTime();
        }
      }
    }, 500),
    suspendPlay1() {
      //广播结束
      if (this.$store.state.calltThePolice != null) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        this.$store.state.isBroadcast = false;
        clearInterval(this.$store.state.calltThePoliceTimer);
      }
    },
    playWav1: _throttle(function () {
      //广播开始
      if (
        this.$store.state.calltThePolice == null &&
        this.$store.state.gbInfoVoice.length > 0 &&
        this.$store.state.isBroadcast == false
      ) {
        this.$store.state.isBroadcast = true;
        clearInterval(this.$store.state.calltThePoliceTimer);
        this.$store.state.calltThePolice = new Audio(
          `${beasconfig.mmsUrlapi}-${this.userState.server}/api/v1/media/download?user=${this.$store.state.gbInfoVoice[0].from}&uuid=${this.$store.state.gbInfoVoice[0].uuid}`
        );
        this.$store.state.calltThePolice.play();
        this.$store.state.calltThePolice.addEventListener("ended", () => {
          //语音播放结束
          this.$store.state.gbInfoVoice.splice(0, 1);
          this.$store.state.isBroadcast = false;
          this.$store.state.calltThePolice = null;
        });
      }
    }, 200),
    startTime() {
      //开始计时
      let that = this;
      // let num = 0;
      if (that.$store.state.calltThePoliceTimer1 != null) {
        //重复调用先清除定时器
        clearTimeout(that.$store.state.calltThePoliceTimer1);
      }
      if (that.$store.state.enclosureTc.length <= 0) {
        //围栏告警没有的时候不计时

        return;
      }
      that.$store.state.calltThePoliceTimer1 = setTimeout(() => {
        that.$store.state.enclosureTc.splice(0, 1);
        if (that.giveanalarmType == 2) {
          if (
            that.$store.state.calltThePolice != null &&
            that.$store.state.isBroadcast == false
          ) {
            //播放语音广播的时候不播放围栏警告
            that.$store.state.calltThePolice.pause();
            that.$store.state.calltThePolice = null;
            clearInterval(that.$store.state.calltThePoliceTimer);
          }
          if (
            !that.$store.state.isSpeech &&
            that.$store.state.enclosureTc.length > 0 &&
            that.$store.state.isBroadcast == false &&
            that.$store.state.voiceCallInfo.callType == 0 &&
            that.$store.state.videoCallInfo.callType == 0
          ) {
            //有语音广播的时候不播围栏警告
            if (that.userState.enclosureVoice == 1) {
              that.$store.state.calltThePolice = new Audio(fence);
              that.$store.state.calltThePolice.play();
            }
          }
          that.startTime();
        }
      }, 5000);
    },
    handleSpeak() {
      let gbInfoText = this.$store.state.gbInfoText;
      if (gbInfoText.length <= 0) {
        return;
      }
      this.textTips();
      if (this.isPlay) {
        //下一条不播放声音
        this.isPlay = false;
        return;
      }
      if (this.$store.state.voiceCallInfo.callType != 0) {
        return;
      }
      if (
        this.$store.state.enclosureTc.length > 0 ||
        (this.$store.state.sosPushInfo.length > 0 &&
          this.$store.state.calltThePolice != null) ||
        this.$store.state.videoCallInfo.callType != 0
      ) {
        //文字广播优先级没有围栏告警高 有围栏告警的时候文字广播停止声音 sos同理

        return;
      }
      window.speechSynthesis.cancel();
      if (this.$store.state.isBroadcast || this.$store.state.isSpeech) {
        //有语音广播并且正在讲话的时候不播放文字广播

        return;
      }
      if (this.$store.state.enclosureTc.length > 0) {
        //播放的时候暂停围栏告警
        this.suspendPlay();
      }
      this.$store.state.isTextBb = true; //暂停历史记录播放
      msg.text = gbInfoText[0].msg; // 文字内容: 小朋友，你是否有很多问号
      msg.lang = "zh-CN"; // 使用的语言:中文
      msg.volume = 5; // 声音音量：1
      msg.rate = 1; // 语速：1
      msg.pitch = 1; // 音高：1
      synth.cancel(msg);
      setTimeout(() => {
        synth.speak(msg); // 播放
        this.$store.state.isTextBb = false;
        msg.onend = () => {
          //播放完成时间
          // if(this.$store.state.isTextBb){
          // this.$store.state.isTextBb = false;
          // this.$store.state.gbInfoText.splice(0,1);
          if (this.$store.state.sosPushInfo > 0) {
            this.giveanalarmType = 1; //播放sos
            this.playWav();
          }
          // }
        };
      }, 200);
    },
    textTips() {
      //文字广播提示
      this.$message.closeAll();
      this.$message({
        iconClass: " ",
        dangerouslyUseHTMLString: true,
        message: `<div><img onclick="closeMaker(1)" class="mouse" style="width: 20px;height: 20px;" src="${textGb2x}" alt=""><div class="text" >${this.$store.state.gbInfoText[0].msg}</div> <img onclick="closeMaker(2)" class="mouse" style="width: 20px;height: 20px;" src="${textGb2x1}" alt=""></div>`,
        duration: 0,
        offset: 90,
        customClass: "textGb",
      });
    },
    handleStop(e) {
      //文字播报关闭
      msg.text = e;
      msg.lang = "zh-CN";
      msg.volume = 0;
      synth.cancel(msg);
    },
    closeMaker(type) {
      //关闭message
      if (type == 1) {
        if (
          this.$store.state.isSpeech ||
          this.$store.state.isBroadcast ||
          this.$store.state.enclosureTc.length > 0 ||
          (this.$store.state.sosPushInfo.length > 0 &&
            this.$store.state.calltThePolice != null)
        ) {
          //文字广播优先级没有围栏告警高 有围栏告警的时候文字广播停止声音 sos同理

          return;
        }
        this.suspendPlay();
        this.handleSpeak();
      } else {
        this.isPlay = true; //点击下一条的时候不播放
        this.textTips();
        this.handleStop();
        this.$store.state.gbInfoText.splice(0, 1);
        if (this.$store.state.gbInfoText.length <= 0) {
          //小于0的时候关闭
          this.$message.closeAll();
        }
      }
    },
  },
  watch: {
    // "$store.state.groupOnline": {
    //   handler(newValue) {
    //     if (newValue.length > 0) {
    //       let that = this;
    //       let fixedGroup = newValue[0].GroupType.filter(
    //         (item) => item.GroupType == 1
    //       );
    //       let userTotal = newValue[0].onlineArray.filter(
    //         (item) => item.online == 3
    //       );
    //       that.statistics.fixedGroup = fixedGroup.length;
    //       that.statistics.userTotal = newValue[0].onlineArray.length;
    //       let num = JSON.stringify(userTotal.length).padStart(5, "0");
    //       let m = num.split("");
    //       that.statistics.onlineUserTotal = m;
    //       // that.statistics.onlineUserTotal = [
    //       // m[0] ? m[0] : 0,
    //       // m[1] ? m[1] : 0,
    //       // m[2] ? m[2] : 0,
    //       // m[3] ? m[3] : 0,
    //       // m[4] ? m[4] : 0,
    //       // ];
    //     }
    //   },
    //   // deep: true,
    //   immediate: true,
    // },
    "$store.state.groupUserData": {
      handler(newValue) {
        this.groupArray = this.xuanze([...newValue]).filter(
          (item) => item.mmingroup != 1
        );
      },
      // deep: true,
      immediate: true,
    },
    "$store.state.sosPushInfo"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
        //满足条件直接触发sos告警 sos优先级最高
        if (
          this.userState.sosVoice == 1 &&
          this.$store.state.calltThePoliceType == 0
        ) {
          // this.$store.state.enclosureTc = [];
          this.$store.state.isBroadcast = false;
          this.giveanalarmType = 1;
          this.handleStop();
          this.suspendPlay();
          this.playWav();
        }
      } else {
        if (this.$store.state.calltThePoliceType == 1) {
          this.suspendPlay();
        }
      }
    },
    "$store.state.enclosureTc"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
        // 满足条件先暂停在播放围栏告警
        if (
          this.$store.state.calltThePolice == null &&
          this.$store.state.sosPushInfo.length <= 0 &&
          this.$store.state.isBroadcast == false
        ) {
          this.giveanalarmType = 2;
          this.handleStop();
          this.suspendPlay();
          this.playWav();
        } else {
          this.startTime(); //无声弹窗
        }
      }
    },
    "$store.state.gbInfoText"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
        // this.textTips();
        this.handleSpeak();
      }
    },
    "$store.state.isSpeech"(newVal) {
      //有人讲话
      //对数据执行操作
      if (newVal) {
        if (this.$store.state.enclosureTc.length > 0) {
          this.startTime();
        }
        if (this.$store.state.gbInfoText.length > 0) {
          this.handleStop();
        }
      }
    },
    "$store.state.videoCallInfo.callType"(type) {
      if (type != 0) {
        this.handleStop();
        this.suspendPlay();
      }
    },
    "$store.state.voiceCallInfo.callType"(type) {
      if (type != 0) {
        this.handleStop();
        this.suspendPlay();
      }
    },
    // "$store.state.gbInfoVoice"(newVal) {
    //   //对数据执行操作
    //   if (newVal.length > 0) {
    //     // this.$store.state.calltThePolice = null;
    //     // this.$store.state.sosPushInfo = [];
    //     // this.textTips();
    //     // if(!this.$store.state.isBroadcast && !this.$store.state.isTextBb){
    //     //   this.suspendPlay();
    //     // }
    //     // if (
    //     //   this.$store.state.gbInfo.length > 0 &&
    //     //   this.$store.state.gbInfo[0].msg_type == 3
    //     // ) {
    //     //   // this.playWav1();
    //     // } else {
    //     //   this.textTips();
    //     //   // if(!this.$store.state.isTextBb){
    //     //   //   this.handleSpeak();
    //     //   // }
    //     // }
    //   }
    // }
  },
};
</script>
<style lang="less">
.textGb {
  background-color: #fbfbde !important;
  color: #000000;

  .el-message__content > div {
    display: flex;
    align-items: center;

    .text {
      min-width: 380px;
      flex: 1;
      line-height: 20px;
      margin: 0px 5px;
    }
  }
}
</style>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
}

.sos {
  width: 395px;
  // height: 335px;
  max-height: 335px;
  // width: 315px;
  // height: 260px;
  background: url("../assets/sos/sos_tck.png");
  background-size: 100% 100%;
  position: fixed;
  right: 7px;
  bottom: 12px;
  color: #fff;
  z-index: 16;

  &_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 15px;
  }

  &_gb {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 12px;
    top: 35px;
    // top: 25px;
  }

  &_bottom {
    width: 100%;
    justify-content: center;
  }

  &_Circular {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: red;
    border: 1px solid #ffffff;
    position: absolute;
    font-size: 12px;
    right: 158px;
    top: 0px;
    z-index: 8;
  }

  &_content {
    width: 90%;
    flex: 1;
    // height: 140px;
    margin: 30px auto;
    text-align: left;
    font-size: 18px;
    color: #f2f2f2;

    span {
      font-weight: bold;
    }

    &_sl {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_address {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &_but {
    width: 135px;
    height: 45px;
    line-height: 45px;
    // background-color: #48d3d4;
    border-radius: 50px;
  }
}

.tz {
  width: 48px;
  height: 48px;
  position: fixed;
  bottom: 6px;
  right: 5px;
  z-index: 1001;
}

.giveAnAlarm {
  width: 300px;
  height: 300px;
  // max-height: 335px;
  background-color: #000000;
  opacity: 0.75;
  position: fixed;
  right: 10px;
  bottom: 12px;
  color: #f8f8f8;
  border-radius: 10px;
  border: 1px solid red;
  z-index: 1000;

  &_gb {
    position: absolute;
    right: 8px;
    top: -1px;
    font-size: 25px;
  }

  &_title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
  }

  &_info {
    flex: 1;
    height: calc(300px - 70px - 30px);
    margin: 15px 10%;
    text-align: left;
    font-size: 14px;

    & > div {
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-bottom: 5px;
    }

    &_one {
      width: 65px;
    }
  }

  &_tips {
    font-size: 12px;
    color: #ccc;
  }
}

.customer_height {
  height: calc(100vh - 380px - 163px - 110px);
}

.flex_align {
  display: flex;
  align-items: center;
}

.mag_bottom {
  margin-bottom: 15px;
}
</style>
