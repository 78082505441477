export default [    //历史记录
    {
        path: "/history/videorecord",  
        name:'videorecord',
        meta: { navId:4 },
        component:() => import("@/views/history/videorecord.vue")
    },
    {
        path: "/history/recording",  
        name:'recording',
        meta: { navId:4 },
        component:() => import("@/views/history/recording.vue")
    },
    {
        path: "/history/sosPosition",  
        name:'sosPosition',
        meta: { navId:4 },
        component:() => import("@/views/history/sosPosition.vue")
    },
    {
        path: "/history/radiobroadcast",  
        name:'radiobroadcast',
        meta: { navId:4 },
        component:() => import("@/views/history/radiobroadcast.vue")
    },
    {
        path: "/history/sosrecord",  
        name:'sosrecord',
        meta: { navId:4 },
        component:() => import("@/views/history/sosrecord.vue")
    },
    {
        path: "/history/uploadRecord",  
        name:'uploadRecord',
        meta: { navId:4 },
        component:() => import("@/views/history/uploadRecord.vue")
    }
]