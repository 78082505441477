export default [    //调度对讲
    {
        path: "/intercom/trajectory",
        name: 'trajectory',
        meta: { title: "芯对讲平台", navId: 1, isRecord: true },
        component: () => import("@/views/intercom/trajectory.vue")
    },
    {
        path: "/intercom/baiduMap",
        name: 'baiduMap',
        meta: { title: "芯对讲平台", navId: 1 },
        component: () => import("@/views/intercom/baiduMap.vue")
    }
]